import queryString from 'query-string';

import {
  URL,
  REQUEST_PARTNERS,
  RECEIVE_PARTNERS,
  PARTNERS_LIMIT
} from '../lib/actions';

import { getQueryParametres } from '../lib/functions';

import { logout } from './user';

function requestPartners() {
  return {
    type: REQUEST_PARTNERS
  };
}

function receivePartners(response) {
  return {
    type: RECEIVE_PARTNERS,
    response
  };
}

export function fetchPartners(query = { page: 1, limit: 7 }) {
  return async (dispatch, getState) => {
    dispatch(requestPartners());
    const request = await fetch(
      `${URL}/admin/partners?${getQueryParametres(query)}`,
      {
        method: 'GET',
        headers: {
          'x-access-token': getState().user.token
        }
      }
    );
    const { status } = await request;
    if (status === 200) {
      const response = await request.json();
      dispatch(receivePartners(response));
    } else {
      if (status === 401) {
        dispatch(logout());
      }
    }
  };
}

export function searchPartners(query) {
  return async (dispatch, getState) => {
    dispatch(requestPartners());
    const request = await fetch(
      `${URL}/admin/partners/search?${getQueryParametres(query)}`,
      {
        method: 'GET',
        headers: {
          'x-access-token': getState().user.token
        }
      }
    );
    const { status } = await request;
    if (status === 200) {
      const response = await request.json();
      dispatch(receivePartners(response));
    } else {
      if (status === 401) {
        dispatch(logout());
      }
    }
  };
}

export function addPartner(data, cb) {
  return async (dispatch, getState) => {
    const request = await fetch(`${URL}/admin/partners`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getState().user.token
      },
      body: JSON.stringify(data)
    });
    const { status } = await request;
    if (status === 200) {
      const response = await request.json();
      if (cb)
        cb(`id: ${response.data.id}\npassword: ${response.data.password}`);
      // eslint-disable-next-line
      console.log(
        `id: ${response.data.id}\npassword: ${response.data.password}`
      );

      dispatch(
        fetchPartners({
          ...queryString.parse(window.location.search),
          limit: PARTNERS_LIMIT
        })
      );
    } else {
      switch (status) {
        case 422:
          alert(
            'На этот адрес невозможно отправить приглашение. Укажите другой адрес'
          );
          break;

        case 409:
          alert('Данный адрес уже используется');
          break;

        default:
          break;
      }
    }
    return status;
  };
}

export function changePartner(data, id) {
  return async (dispatch, getState) => {
    const request = await fetch(`${URL}/admin/partners/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getState().user.token
      },
      body: JSON.stringify(data)
    });
    const { status } = await request;
    if (status === 200) {
      dispatch(
        fetchPartners({
          ...queryString.parse(window.location.search),
          limit: PARTNERS_LIMIT
        })
      );
    } else {
      switch (status) {
        case 400:
          alert('Заполните все поля');
          break;

        default:
          break;
      }
    }
    return status;
  };
}

export function uploadImage(file, userType = 'admin') {
  return async (dispatch, getState) => {
    const formData = new FormData();
    formData.append('image', file);
    const request = await fetch(`${URL}/${userType}/upload/image`, {
      method: 'POST',
      headers: {
        'x-access-token': getState().user.token
      },
      body: formData
    });
    const { status } = await request;
    if (status === 200) {
      const response = await request.json();

      return response;
    }
    switch (status) {
      default:
        break;
    }
  };
}

export function restorePassword(id, cb) {
  return async (dispatch, getState) => {
    const request = await fetch(
      `${URL}/admin/partners/${id}/generate_password`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': getState().user.token
        }
      }
    );
    const { status } = await request;
    if (status === 200) {
      const response = await request.json();
      if (cb) cb(`Пароль: ${response.data.newPassword}`);
      // eslint-disable-next-line
      console.log(`password: ${response.data.newPassword}`);
    } else {
    }
    return status;
  };
}
