import React, { useEffect } from 'react';

import Button from '../../components/Button';

import Modal from '../../components/Modal';

const confirmationModal = ({ isOpen, onClose, message }) => (
  <Modal
    className='modal-confirmation'
    close={onClose}
    open={isOpen}
    title=''
  >
    <div className='message-block'>
      {message.split('\n').map((el, i) => (
        <div
          className='message-block__line'
          key={`message-line-${i}`}
        >
          {el}
        </div>
      ))}
    </div>
    <Button
      label='Ok'
      onClick={onClose}
    />
  </Modal>
);
export default confirmationModal;
