import React from 'react';

import './style.scss';

export default class extends React.Component {
  state = {
    open: false,
    search: ''
  };

  componentDidMount() {
    document.addEventListener('click', this.windowEvent);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.windowEvent);
  }

  dropdown = React.createRef();

  windowEvent = event => {
    const { target } = event;

    if (target) {
      const el = target.closest('.select');

      if (!this.dropdown.current) return;
      if (el === this.dropdown.current) return;

      if (this.state.open) {
        this.setState({
          open: false
        });
      }
    }
  };

  @bind
  handleClick(e) {
    const { open } = this.state;
    if (e.target !== this.dropdown.current && open && this.props.multiple)
      return;
    this.setState({ open: !open });
  }

  @bind
  handleChange(value) {
    this.setState({ search: '' });
    this.props.onChange(value);
  }

  @bind
  handleSearch(e) {
    this.setState({ search: e.target.value });
  }

  @bind
  getClassName() {
    let className = 'select';
    if (this.props.className) {
      className += ` ${this.props.className}`;
    }
    if (this.state.open) {
      className += ' select-open';
    }
    if (this.props.alwaysOpen) {
      className += ' select-always-open';
    }
    if (this.props.variant === 'second') className += ' variant-second';
    return className;
  }

  @bind
  getMatch() {
    const { value, options } = this.props;

    return options.find(option => String(option.value) === String(value));
  }

  @bind
  getTitle() {
    const { value, placeholder } = this.props;

    if (!value) return placeholder;

    const match = this.getMatch();

    if (match) {
      return match.name;
    }
    return placeholder;
  }

  @bind
  getOptionClassName(optionValue) {
    if (this.props.multiple) {
      return this.props.value.includes(optionValue)
        ? 'select-option select-option-active'
        : 'select-option';
    }

    return optionValue === this.props.value
      ? 'select-option select-option-active'
      : 'select-option';
  }

  @bind
  getFilteredOptions() {
    const { options } = this.props;
    const { search } = this.state;

    if (!search) return options;
    return options.filter(option =>
      option.name.toLowerCase().includes(search.toLowerCase())
    );
  }

  @bind
  renderTitle() {
    return this.props.search ? (
      <input
        onChange={this.handleSearch}
        placeholder={this.getTitle()}
        value={this.state.search}
      />
    ) : (
      this.getTitle()
    );
  }

  @bind
  renderOptions() {
    return this.getFilteredOptions().map((option, i) => (
      <div
        className={this.getOptionClassName(option.value)}
        key={i}
        onClick={() => this.handleChange(option.value)}
      >
        {option.name}
      </div>
    ));
  }

  @bind
  renderLabel() {
    const { placeholder } = this.props;
    const { search } = this.state;
    const match = this.getMatch();

    if (!placeholder || (!search && (!match || !match.value))) return null;
    return <div className='select-label'>{placeholder}</div>;
  }

  render() {
    const { style } = this.props;

    return (
      <div
        className={this.getClassName()}
        onClick={this.handleClick}
        ref={this.dropdown}
        style={style}
      >
        {this.renderLabel()}
        <div className='select-title'>{this.renderTitle()}</div>
        <div className='select-option-list'>{this.renderOptions()}</div>
      </div>
    );
  }
}
