import React from 'react';
import RichTextEditor from 'react-rte/lib/RichTextEditor';

import './style.scss';

const EditField = ({ value, onChange, label }) => (
  <div className='edit-field'>
    <div className='edit-field-label'>
      <label>{label}</label>
    </div>
    <RichTextEditor
      onChange={onChange}
      toolbarConfig={{
        display: ['LINK_BUTTONS']
      }}
      value={value || RichTextEditor.createEmptyValue()}
    />
  </div>
);

export default EditField;
