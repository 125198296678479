import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Authorization from '../../components/authorization';
import routes from '../../routes/routes';

const withToken = WrappedComponent => {
  const Component = props => {
    const token = useSelector(state => state.user.token);
    const location = useLocation();
    const isAuthStatusPage = routes.authStatusPage.path.includes(
      location.pathname
    );

    return !token && !isAuthStatusPage ? (
      <Authorization />
    ) : (
      <WrappedComponent {...props} />
    );
  };

  return Component;
};

export default withToken;
