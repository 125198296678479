import React from 'react';

import './style.scss';
import { getRandom } from '../../lib/functions';

class Input extends React.PureComponent {
  @bind
  getClassName() {
    const { className, value } = this.props;
    let cn = 'Input';
    if (className) cn += ` ${className}`;
    if (value || value === 0) cn += ' Input_active';
    return cn;
  }

  render() {
    const { label, component } = this.props;
    const Component = component || 'input';

    const inputProps = { ...this.props };
    delete inputProps.component;

    const labelId = `${label}-${getRandom()}`;

    return (
      <div className={this.getClassName()}>
        <Component
          id={labelId}
          {...inputProps}
          className='Input__TextField'
        />
        {label && <label htmlFor={labelId}>{label}</label>}
      </div>
    );
  }
}

export default Input;
