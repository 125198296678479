import React from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';

import InputMask from 'react-input-mask';

import Checkbox from '../../components/checkbox';
import Select from '../../components/Select';
import { withModalContainer } from '../../components/oldModal';
import Input from '../../components/oldInput';

const formatPercentage = value => {
  const v = parseInt(value, 10);
  if (v > 100) return '100';
  if (v < 0) return '0';
  if (!value) return '0';
  return value;
};

export default withModalContainer(
  ({ values, events, edit, restorePassword, types }) => {
    const img = values.src || values.logo;

    const handleChangeFullName = e => {
      const { value } = e.target;
      if (value.length <= 250) {
        events.handleChange('fullName')(e);
      }
    };

    const handleChangeCashback = e => {
      let val = formatPercentage(e.target.value);
      val = val.replace(/%/g, '');
      events.defaultChangeHandler({ cashback: val });
    };

    const changePlacesCount = e => {
      if (parseInt(e.target.value, 10) < 0) e.target.value = '0';

      events.handleChange('placesCount')(e);
    };

    const handleCheckAcceessible = () => {
      events.defaultChangeHandler({
        active: false,
        accessible: !values.accessible
      });
    };

    const handleCheckActive = () => {
      if (values.accessible)
        events.defaultChangeHandler({ active: !values.active });
    };

    return (
      <>
        {edit && (
          <div className='modal__info'>
            <span className='info__title'>Рейтинг</span>
            <div className='info__value'>{values.rating}</div>
          </div>
        )}
        <Input
          inputProps={{
            maxLength: 250,
            onChange: events.handleChange('shortName'),
            value: values.shortName
          }}
          label='Наименование'
        />
        <Input
          className='textarea'
          inputComponent='textarea'
          inputProps={{
            onChange: handleChangeFullName,
            value: values.fullName
          }}
          label='Краткое описание'
        />
        <Select
          className='form-control'
          onChange={events.handleSelect('type')}
          options={types.map(item => ({
            name: item.name,
            value: item.id
          }))}
          placeholder='Тип'
          value={values.type}
        />
        <Input
          className='textarea'
          inputComponent='textarea'
          inputProps={{
            onChange: events.handleChange('description'),
            rows: '4',
            value: values.description
          }}
          label='Полное описание'
        />
        <Input
          inputComponent={NumberFormat}
          inputProps={{
            onChange: handleChangeCashback,
            suffix: '%',
            value: formatPercentage(values.cashback)
          }}
          label='Кэшбек'
        />
        <Input
          inputComponen={InputMask}
          inputProps={{
            mask: '+7 (999) 999 99 99',
            maskChar: '',
            onChange: events.handleChange('phone', 'contacts'),
            value: values.contacts.phone
          }}
          label='Телефон'
        />
        <Input
          inputProps={{
            maxLength: 250,
            onChange: edit ? () => {} : events.handleChange('email'),
            value: values.email
          }}
          label='Почта'
        />
        <Input
          inputProps={{
            onChange: events.handleChange('supportEmail'),
            value: values.supportEmail
          }}
          label='Еmail для связи'
        />
        <Input
          inputProps={{
            onChange: events.handleChange('orderIndex'),
            value: values.orderIndex,
            type: 'number'
          }}
          label='Сортировка'
        />
        <Input
          inputProps={{
            onChange: changePlacesCount,
            value: values.placesCount,
            type: 'number'
          }}
          label='Максимальное количесво филиалов'
        />
        <Input
          inputProps={{
            onChange: events.handleChange('link'),
            value: values.link
          }}
          label='Ссылка на сайт'
        />
        {edit && (
          <button
            className='btn-secondary btn_size_l form-control'
            onClick={() => restorePassword(values.id)}
            type='button'
          >
            Восстановить пароль
          </button>
        )}

        {img && (
          <div className='modal__photo-preview form-control'>
            <img
              alt='Лого'
              src={img}
            />
          </div>
        )}
        <button
          className='btn-primary btn_size_l form-control'
          onClick={events.handleLoadFile('logo')}
          type='button'
        >
          Загрузить изображение
        </button>
        {edit && (
          <div className='modal-group form-control'>
            <Checkbox
              label='Доступен'
              onChange={handleCheckAcceessible}
              value={values.accessible}
            />
            <Checkbox
              label='Активен'
              onChange={handleCheckActive}
              value={values.active}
            />
            <div>
              <div className='modal__info'>
                <Link to={`/admin/products?partnerId=${values.id}`}>
                  Товары
                </Link>
              </div>
              <div className='modal__info'>
                <Link to={`/admin/places?partnerId=${values.id}`}>Филиалы</Link>
              </div>
            </div>
          </div>
        )}
        <Checkbox
          label='Доступ к промокодам'
          onChange={events.handleCheck('promocodesAllowed')}
          value={values.promocodesAllowed}
        />
      </>
    );
  }
);
