import React from 'react';

import { withModalContainer } from '../../components/oldModal';

export default withModalContainer(({ values, events }) => (
  <React.Fragment>
    <input
      className='input'
      onChange={events.handleChange('city')}
      placeholder='Город'
      type='text'
      value={values.city}
    />
    <input
      className='input form-control'
      onChange={events.handleChange('school')}
      placeholder='Школа'
      type='text'
      value={values.school}
    />
    <input
      className='input form-control'
      onChange={events.handleChange('type')}
      placeholder='Тип'
      type='text'
      value={values.type}
    />
    <input
      className='input form-control'
      onChange={events.handleChange('amount')}
      placeholder='Сумма'
      type='number'
      value={values.amount}
    />
  </React.Fragment>
));
