import { URL, LOGIN, LOGOUT, GET_PARTNER_INFO } from '../lib/actions';

export function login(data, userType = 'admin') {
  return async dispatch => {
    const request = await fetch(`${URL}/${userType}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const { status } = await request;

    if (status === 200) {
      alert('Вы успешно авторизовались');
      const response = await request.json();

      dispatch({
        type: LOGIN,
        response
      });
    } else {
      switch (status) {
        case 400:
        case 401:
          alert('Неверный логин или пароль');
          break;

        default:

          break;
      }
    }
  };
}

export function logout() {
  return dispatch => {
    dispatch({
      type: LOGOUT
    });
  };
}

export function getPartnerInfo() {
  return async (dispatch, getState) => {
    const request = await fetch(`${URL}/partner/info`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getState().user.token
      }
    });
    const { status } = await request;

    if (status === 200) {
      const response = await request.json();

      dispatch({
        type: GET_PARTNER_INFO,
        response
      });
    } else {
      switch (status) {
        default:

          break;
      }
    }
  };
}
