import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { getQueryString } from '../../lib/functions';

import './style.scss';

class Pagination extends Component {
  static defaultProps = {
    value: 1
  };

  @bind
  getClassName(num) {
    let className = 'pagination__link';
    if (Number(this.props.value) === num || (num === 1 && !this.props.value))
      className += ' pagination__link_active';
    return className;
  }

  @bind
  renderPageNum(num) {
    const query = getQueryString();
    delete query.page;

    return (
      <Link
        className={this.getClassName(num)}
        key={`page-${num}`}
        to={{
          pathname: this.props.pathname,
          search: `?page=${num}&${queryString.stringify(query)}`
        }}
      >
        {num}
      </Link>
    );
  }

  @bind
  renderPageNumbers(start, end) {
    const result = [];
    for (let i = start; i <= end; i++) {
      result.push(
        <React.Fragment key={i}>{this.renderPageNum(i)}</React.Fragment>
      );
    }
    return result;
  }

  render() {
    const pageNum = Math.ceil(this.props.total / 10);

    if (!this.props.total) return null;

    const curPage = Number(this.props.value);

    let start = 1;
    let end = pageNum;

    if (curPage - 4 > 0) {
      if (curPage > pageNum - 4) start = pageNum - 4;
      else start = curPage - 2;
    }
    if (curPage < pageNum - 3) {
      if (curPage < 5) end = 5;
      else end = curPage + 2;
    }

    return (
      <div className='pagination'>
        {curPage > 4 && (
          <React.Fragment>
            {this.renderPageNum(1)}
            <a className='pagination__link pagination__link_disable'>...</a>
          </React.Fragment>
        )}
        {this.renderPageNumbers(start, end)}
        {curPage < pageNum - 3 && (
          <React.Fragment>
            <a className='pagination__link pagination__link_disable'>...</a>
            {this.renderPageNum(pageNum)}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withRouter(Pagination);
