import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import MetaStat from '../components/MetaStat';

import SearchButton from '../components/SearchButton';

import Table from '../components/table';
import Pagination from '../components/oldPagination';
import Select from '../components/Select';
import Datepicker from '../components/RangeDatePicker';

import { fetchSales, revert } from '../actions/partner-sales';
import { fetchSellers } from '../actions/sellers';
import { fetchBranches } from '../actions/branches';
import { fetchCities } from '../actions/cities';
import { getUpload } from '../actions/export';

import { SALES_LIMIT } from '../lib/actions';

import withPageContainer from '../components/page';
import {
  getQueryString,
  formatDateToOutput,
  formatPhone,
  formatPhoneForQuery,
  pushHistory
} from '../lib/functions';

class SalesPage extends React.Component {
  state = {
    clientPhone: ''
  };

  pushHistory = pushHistory.bind(this);

  componentDidMount() {
    this.props.fetchSellers();
    this.props.fetchBranches();
    this.props.fetchCities();
  }

  @bind
  handleSelect(name) {
    return value => {
      this.pushHistory({ [name]: value, page: 1 });
    };
  }

  @bind
  handleChangeDate(from, to) {
    this.pushHistory({ from, to });
  }

  @bind
  handleSearchByPhone() {
    const value =
      this.state.clientPhone.length !== 18 ? '' : this.state.clientPhone;
    this.pushHistory({
      clientPhone: formatPhoneForQuery(value),
      page: 1
    });
  }

  @bind
  handleChangePhone(event) {
    const value = formatPhone(event.target.value);
    this.setState({ clientPhone: value });
  }

  getSalePhone = sale => {
    if (sale.client) return sale.client.phone;
    return '';
  };

  @bind
  formatData() {
    return this.props.sales.map(sale => ({
      ...sale,
      name: sale.seller.name,
      date: formatDateToOutput(sale.createdAt),
      place: sale.place.address,
      phone: this.getSalePhone(sale),
      info: (
        <button
          className='btn-reset btn-link'
          disabled={!sale.revertable}
          onClick={() => this.props.revert(sale.id)}
        >
          {sale.revertable ? 'Возврат' : 'Возврат недоступен'}
        </button>
      ),
      totalSum: sale.revenue
    }));
  }

  @bind
  formatSellers() {
    return [
      {
        name: 'Все',
        value: ''
      },
      ...this.props.sellers.map(seller => ({
        name: `${seller.lastName} ${seller.firstName} ${seller.middleName}`,
        value: seller.id
      }))
    ];
  }

  @bind
  formatPlaces() {
    return [
      {
        name: 'Все',
        value: ''
      },
      ...this.props.places.map(place => ({
        name: place.address,
        value: place.id
      }))
    ];
  }

  @bind
  formatCities() {
    return [
      {
        name: 'Все',
        value: ''
      },
      ...this.props.cities.map(city => ({
        name: city,
        value: city
      }))
    ];
  }

  render() {
    const { events } = this.props;
    const {
      placeId = '',
      sellerId = '',
      city = '',
      clientPhone = ''
    } = getQueryString();

    return (
      <>
        <div className='header'>
          <h2 className='h2'>Транзакции</h2>
          <button
            className='btn-secondary header__button'
            onClick={() => this.props.getUpload('partner/sales')}
          >
            Экспорт
          </button>
        </div>
        <div className='page-actions'>
          <Select
            onChange={this.handleSelect('placeId')}
            options={this.formatPlaces()}
            placeholder='Филиалы'
            search
            value={placeId}
          />
          <Select
            onChange={this.handleSelect('city')}
            options={this.formatCities()}
            placeholder='Город'
            search
            value={city}
          />
          <Select
            onChange={this.handleSelect('sellerId')}
            options={this.formatSellers()}
            placeholder='Сотрудник'
            search
            value={sellerId}
          />
        </div>
        <div className='page-actions'>
          <SearchButton
            btnProps={{
              onClick: this.handleSearchByPhone,
              className: 'btn-primary',
              title: 'Найти'
            }}
            id='phone-search'
            inputProps={{
              mask: '+7 (999) 999 99 99',
              maskChar: '',
              onChange: this.handleChangePhone,
              placeholder: 'Поиск по телефону клиента',
              value: this.state.clientPhone || formatPhone(clientPhone)
            }}
            style={{ marginRight: '40px' }}
          />
          <Datepicker onChange={this.handleChangeDate} />
        </div>
        <div className='page-stats'>
          <MetaStat
            label='Всего бонусов'
            value={this.props.stats.totalBonus}
          />
          <MetaStat
            label='Всего рублей'
            value={this.props.stats.totalRevenue}
          />
          <MetaStat
            label='Уникальных клиентов'
            value={this.props.stats.totalClients}
          />
          <MetaStat
            label='Продаж совершено'
            value={this.props.stats.totalSales}
          />
        </div>
        <div className='table-container'>
          <Table
            options={[
              { name: 'place', value: 'Филиал' },
              { name: 'name', value: 'Сотрудник' },
              { name: 'products', value: 'Товары / Услуги' },
              { name: 'amount', value: 'Бонусы' },
              { name: 'totalSum', value: 'Выручка' },
              { name: 'date', value: 'Дата' },
              { name: 'phone', value: 'Телефон' },
              { name: 'info', value: '', className: 'modal__info' }
            ]}
            rows={this.formatData()}
          />
        </div>
        <Pagination
          limit={SALES_LIMIT}
          total={this.props.pagination.total}
          value={events.getQueryString('page')}
        />
      </>
    );
  }
}

const Page = withPageContainer(SalesPage, { limit: SALES_LIMIT });

const mapStateToProps = state => ({
  sales: state.partnerSales.items,
  isFetching: state.partnerSales.isFetching,
  pagination: state.partnerSales.pagination,
  stats: state.partnerSales.stats,
  sellers: state.sellers.items,
  places: state.branches.items,
  cities: state.cities.items
});

const mapDispatchToProps = dispatch => ({
  revert: (...args) => dispatch(revert(...args)),
  fetch: (...args) => dispatch(fetchSales(...args)),
  getUpload: (...args) => dispatch(getUpload(...args)),
  fetchCities: (...args) => dispatch(fetchCities(...args)),
  fetchSellers: (...args) => dispatch(fetchSellers(...args)),
  fetchBranches: (...args) => dispatch(fetchBranches(...args))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Page)
);
