import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button from '../components/Button';

import Select from '../components/Select';

import Table from '../components/table';
import Modal from '../modals/sellers-modal';
import Checkbox from '../components/checkbox';
import Pagination from '../components/oldPagination';
import SearchButton from '../components/SearchButton';

import { fetchSellers, addSeller, changeSeller } from '../actions/sellers';

import withPageContainer from '../components/page';
import {
  formatPhone,
  formatPhoneForQuery,
  getQueryString,
  pushHistory
} from '../lib/functions';

class SellersPage extends React.Component {
  pushHistory = pushHistory.bind(this);

  state = { phone: '' };

  @bind
  handleSelect(name) {
    return value => {
      this.pushHistory({ [name]: value, page: 1 });
    };
  }

  @bind
  formatData() {
    return this.props.sellers.map((seller, id) => ({
      ...seller,
      name: `${seller.lastName} ${seller.firstName} ${seller.middleName}`,
      active: <Checkbox value={seller.active} />,
      info: (
        <button
          className='btn-reset btn-link'
          onClick={() => this.props.events.toggleEditModal(id)}
        >
          Инфо
        </button>
      )
    }));
  }

  @bind
  handleChangePhone(event) {
    const value = formatPhone(event.target.value);
    this.setState({ phone: value });
  }

  @bind
  handleSearchByPhone() {
    const value = this.state.phone.length !== 18 ? '' : this.state.phone;
    this.pushHistory({
      phone: formatPhoneForQuery(value),
      page: 1
    });
  }

  render() {
    const { events, values } = this.props;
    const data = this.props.sellers[values.editModalId] || { comission: '2' };
    const { phone, active } = getQueryString();

    return (
      <React.Fragment>
        <div className='header'>
          <h2 className='h2'>Сотрудники</h2>
        </div>
        <div className='page-actions'>
          <SearchButton
            btnProps={{
              onClick: this.handleSearchByPhone,
              className: 'btn-primary',
              title: 'Найти'
            }}
            id='phone-search'
            inputProps={{
              mask: '+7 (999) 999 99 99',
              maskChar: '',
              onChange: this.handleChangePhone,
              placeholder: 'Поиск по телефону',
              value: this.state.phone || formatPhone(phone)
            }}
          />
          <Button
            label='Добавить'
            onClick={events.toggleModal}
            style={{ marginLeft: 'auto' }}
          />
        </div>
        <div className='page-actions'>
          <Select
            onChange={this.handleSelect('active')}
            options={[
              {
                name: 'Все',
                value: ''
              },
              {
                name: 'Активные',
                value: 'true'
              },
              {
                name: 'Неактивные',
                value: 'false'
              }
            ]}
            placeholder='Отсортировать'
            style={{ maxWidth: '300px' }}
            value={active}
          />
        </div>
        <div className='table-container'>
          <Table
            options={[
              { name: 'name', value: 'ФИО' },
              { name: 'email', value: 'Email' },
              { name: 'phone', value: 'Телефон' },
              { name: 'comission', value: 'Комиссия' },
              { name: 'active', value: 'Доступен' },
              { name: 'info', value: '', className: 'modal__info' }
            ]}
            rows={this.formatData()}
          />
        </div>
        <Modal
          action={{
            name: 'Добавить',
            onClick: this.props.addSeller,
            className: 'btn-primary'
          }}
          defaultState={{
            firstName: '',
            lastName: '',
            middleName: '',
            active: true,
            email: '',
            password: '',
            comission: 2
          }}
          onToggle={events.toggleModal}
          open={values.isModalOpen}
          title='Добавление сотрудника'
        />
        <Modal
          action={{
            name: 'Сохранить',
            onClick: this.props.changeSeller,
            className: 'btn-secondary'
          }}
          defaultState={data}
          edit
          onToggle={events.toggleEditModal}
          open={values.isOpenEditModal}
          title='Информация'
        />
        <Pagination
          total={this.props.pagination.total}
          value={events.getQueryString('page')}
        />
      </React.Fragment>
    );
  }
}

const Page = withPageContainer(SellersPage);

const mapStateToProps = state => ({
  sellers: state.sellers.items,
  isFetching: state.sellers.isFetching,
  pagination: state.sellers.pagination
});

const mapDispatchToProps = dispatch => ({
  addSeller: (...args) => dispatch(addSeller(...args)),
  changeSeller: (...args) => dispatch(changeSeller(...args)),
  fetch: (...args) => dispatch(fetchSellers(...args))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Page)
);
