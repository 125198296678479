import React from 'react';

import Button from '../components/Button';
import Checkbox from '../components/checkbox';
import EditField from '../components/EditField';
import Input from '../components/Input';
import RangeDatePicker from '../components/RangeDatePicker';

import { modalBind, parseInteger } from '../lib/functions';

const promocodesModal = modal => {
  const bind = modalBind.bind(modal);
  const img = modal.state.src || modal.state.data.image;

  const changePeriod = (from, to) => {
    modal.setDataState({ from, to });
  };

  const onChangeSale = e => {
    let value = parseInteger(e.target.value);
    if (parseInt(value, 10) > 100) value = '100';
    modal.setDataState({ sale: value });
  };

  const onChangePrice = e => {
    const value = parseInteger(e.target.value);
    modal.setDataState({ price: value });
  };

  const onChangeDescription = description => {
    modal.setDataState({ description });
  };

  const { indefinitely, from, to, sale, price, description } = modal.state.data;

  return (
    <>
      <Input
        label='Название'
        {...bind('name')}
      />
      {img && (
        <div className='photo-preview'>
          <img
            alt='Лого'
            src={img}
          />
        </div>
      )}
      <Button
        label='Добавить изображение'
        onClick={modal.handleLoadFile('image', modal.props.userType)}
        type='button'
      >
        Загрузить изображение
      </Button>
      <EditField
        label='Описание'
        onChange={onChangeDescription}
        value={description}
      />
      <div className='modal-group'>
        <p className='static'>Срок акции</p>
        <Checkbox
          label='Бессрочная'
          onChange={modal.handleCheck('indefinitely')}
          value={indefinitely}
        />
      </div>
      <div className='modal-group'>
        <p className='static'>Период</p>
        <RangeDatePicker
          disabled={indefinitely}
          drops='up'
          from={from}
          onChange={changePeriod}
          to={to}
        />
      </div>
      <Input
        label='Цена в бонусах'
        onChange={onChangePrice}
        value={price}
      />
      <Input
        label='Процент скидки'
        onChange={onChangeSale}
        value={sale}
      />
    </>
  );
};

export default promocodesModal;
