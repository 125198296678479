import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';

import { initApp } from '../actions';

import Router from '../routes/Router';

class App extends Component {
  componentDidMount() {
    this.props.initApp();
  }

  render() {
    return (
      <>
        <Router />
      </>
    );
  }
}

const mapDispatchToProps = {
  initApp
};

const Application = connect(
  null,
  mapDispatchToProps
)(App);

export default hot(module)(props => <Application {...props} />);
