import React from 'react';
import 'daterangepicker';
import moment from 'moment';
import 'moment/locale/ru';

import './style.scss';
import { getRandom } from '../../lib/functions';

class Datepicker extends React.Component {
  componentDidMount() {
    this._renderDatePicker();
  }

  componentDidUpdate(prevProps) {
    const { from, to } = this.props;

    if (from !== prevProps.from || to !== prevProps.to) {
      this._renderDatePicker();
    }

    return true;
  }

  componentWillUnmount() {
    this._removeDatePicker();
  }

  id = `daterangepicker${getRandom()}`;

  config = () => ({
    ranges: {
      Сегодня: [moment(), moment()],
      Вчера: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'За 7 дней': [moment().subtract(6, 'days'), moment()],
      'За 30 дней': [moment().subtract(29, 'days'), moment()],
      'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
      'Последний месяц': [
        moment()
          .subtract(1, 'month')
          .startOf('month'),
        moment()
          .subtract(1, 'month')
          .endOf('month')
      ],
      'Посл. 3 месяца': [
        moment()
          .subtract(3, 'month')
          .startOf('month'),
        moment()
          .subtract(1, 'month')
          .endOf('month')
      ],
      'Посл. год': [
        moment()
          .subtract(12, 'month')
          .startOf('month'),
        moment()
          .subtract(1, 'month')
          .endOf('month')
      ],
      'Все время': [
        moment()
          .subtract(60, 'month')
          .startOf('month'),
        moment()
      ]
    },
    locale: {
      format: 'DD.MM.YYYY',
      separator: ' - ',
      applyLabel: 'Применить',
      cancelLabel: 'Отмена',
      fromLabel: 'От',
      toLabel: 'До',
      customRangeLabel: 'Своя дата',
      daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
      monthNames: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'
      ],
      firstDay: 1
    },
    alwaysShowCalendars: true,
    drops: this.props.drops,
    ...this.getDates()
  });

  containerRef = React.createRef();

  _removeDatePicker = () => {
    $(`[id="${this.id}"]`).remove();
  };

  _renderDatePicker = () => {
    $(`#${this.id} input`).daterangepicker(this.config(), this.handleChange);
    $(`#${this.id} input`).on('show.daterangepicker', this.onShow);
  };

  @bind
  onShow(e, picker) {
    picker.container.attr('data-id', this.id);
  }

  handleChange = (start, end) => {
    const from = moment(start).toISOString();
    const to = moment(end).toISOString();

    this.props.onChange(from, to);
  };

  @bind
  getDates() {
    return {
      startDate: this.props.from
        ? moment(this.props.from).format('DD/MM/YYYY')
        : moment(),
      endDate: this.props.to
        ? moment(this.props.to).format('DD/MM/YYYY')
        : moment()
    };
  }

  render() {
    const { disabled } = this.props;

    return (
      <div
        className='rangedatepicker-container'
        id={this.id}
        ref={this.containerRef}
      >
        <input
          className='rangedatepicker-input'
          disabled={disabled}
        />
      </div>
    );
  }
}
export default Datepicker;
