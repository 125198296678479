import {
  deletePromocodeGroupAction,
  generatePromocodeAction,
  getPromocodesGroupByIdAction,
  getPromocodesGroupsAction,
  postPromocodesGroupAction,
  putPromocodesGroupAction
} from '../lib/actions';
import { getQueryParametres, getQueryString } from '../lib/functions';
import request from '../lib/request';

const failed = response => {
  alert(response.message);
};

export const getPromocodesGroups = (
  query = getQueryString(),
  path = 'admin'
) => async dispatch => {
  const success = response => {
    dispatch(getPromocodesGroupsAction(response));
  };

  await request(`/${path}/promocodes/groups?${getQueryParametres(query)}`, {
    success
  });
};

export const getPromocodesGroupById = (
  id,
  query = getQueryString(),
  path = 'admin'
) => async dispatch => {
  const success = response => {
    dispatch(getPromocodesGroupByIdAction(response));
  };

  await request(
    `/${path}/promocodes?groupId=${id}&${getQueryParametres(query)}`,
    {
      success
    }
  );
};

export const deletePromocodeGroup = id => async dispatch => {
  const success = () => {
    dispatch(deletePromocodeGroupAction(id));
  };

  await request(`/partner/promocodes/groups/${id}`, {
    method: 'DELETE',
    success
  });
};

export const postPromocodesGroup = (
  data,
  path = 'admin',
  callback
) => async dispatch => {
  const success = response => {
    dispatch(postPromocodesGroupAction(response));
    dispatch(getPromocodesGroups(undefined, path));
  };

  await request(`/${path}/promocodes/groups/`, {
    body: JSON.stringify(data),
    done: callback,
    method: 'POST',
    failed,
    success
  });
};

export const putPromocodesGroup = (
  data,
  id,
  path = 'admin',
  callback
) => async dispatch => {
  const success = response => {
    dispatch(putPromocodesGroupAction(response));
    dispatch(getPromocodesGroups(undefined, path));
  };

  await request(`/${path}/promocodes/groups/${id}`, {
    body: JSON.stringify(data),
    done: callback,
    method: 'PUT',
    success,
    failed
  });
};

export const generatePromocode = (
  data,
  id,
  path = 'admin',
  callback
) => async dispatch => {
  const success = response => {
    dispatch(generatePromocodeAction(response));
    dispatch(getPromocodesGroupById(id, undefined, path));
  };

  let body = JSON.stringify(data);
  let headers = { 'Content-Type': 'application/json' };

  if (data instanceof File) {
    const fd = new FormData();
    fd.append('file', data);
    body = fd;
    headers = {};
  }

  await request(`/${path}/promocodes/groups/${id}/generate`, {
    done: callback,
    method: 'POST',
    success,
    headers,
    failed,
    body
  });
};

export const offPromocodeGroup = (id, path) => async dispatch => {
  const success = () => {
    dispatch(getPromocodesGroups(undefined, path));
  };

  await request(`/${path}/promocodes/groups/${id}/off`, {
    method: 'POST',
    success
  });
};

export const deletePromocodes = (groupId, success) => async () => {
  await request(`/partner/promocodes/ingroups/${groupId}`, {
    method: 'DELETE',
    success,
    failed
  });
};
