export async function getSuggestions(query, count = 6) {
  const request = await fetch(
    'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token 74c52b4611d8fe024a65dad25d519f6bad7f6fdf'
      },
      body: JSON.stringify({ ...query, count })
    }
  );
  return request.json();
}
