import React from 'react';

import './style.scss';

const getClassName = props => {
  let className = 'input';
  if (props.className) className += ` ${props.className}`;
  if (props.inputProps.value || props.inputProps.value === 0)
    className += ' input_active';
  return className;
};

const getinputTag = (props, id) => {
  const Component = props.inputComponent || 'input';

  return <Component
    id={id}
    type={props.type}
    {...props.inputProps}
  />;
};

const input = props => (
  <div className={getClassName(props)}>
    {getinputTag(props, props.label)}
    <label htmlFor={props.label}>{props.label}</label>
  </div>
);

export default input;
