import {
  GET_SETTINGS,
  GET_SUBJECTS,
  GET_TRANSACTION_LIMIT
} from '../lib/actions';

const STATE = {
  cashback: 0,
  referralBonus: 0,
  registerBonus: 0,
  incorrectSubjects: [],
  types: [],
  subjects: [],
  transactionLimit: 0
};

export default function(state = STATE, action) {
  switch (action.type) {
    case GET_TRANSACTION_LIMIT:
      return {
        ...state,
        transactionLimit: action.response.month_limit
      };

    case GET_SETTINGS:
      return {
        ...state,
        ...action.response
      };

    case GET_SUBJECTS:
      return {
        ...state,
        subjects: action.response.data
      };

    default:
      return state;
  }
}
