import React, { Component } from 'react';
import './style.scss';

class MetaStat extends Component {
  render() {
    const { label, value = '' } = this.props;
    let val = 0;
    if (typeof value === 'number') val = value.toLocaleString();

    return (
      <div className='meta-stat'>
        <p className='stat-value'>{val}</p>
        <p className='stat-label'>{label}</p>
      </div>
    );
  }
}

export default MetaStat;
