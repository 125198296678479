import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SVG from 'react-svg';

import Select from '../components/Select';

import Table from '../components/table';
import Checkbox from '../components/checkbox';
import Pagination from '../components/oldPagination';
import EditModal from '../modals/branches-modal';

import {
  fetchBranches,
  addBranch,
  changeBranch,
  deleteBranch
} from '../actions/branches';
import { fetchPartners } from '../actions/partners';

import { BRANCHES_LIMIT } from '../lib/actions';

import withPageContainer from '../components/page';
import { getQueryString } from '../lib/functions';

import trash from '../image/trash.svg';

class BranchesPage extends React.Component {
  componentDidMount() {
    this.props.getPartners({ limit: 9999 });
  }

  @bind
  formatData() {
    return this.props.branches.map((branch, id) => ({
      ...branch,
      active: <Checkbox value={branch.active} />,
      info: (
        <button
          className='btn-reset btn-link'
          onClick={() => this.props.events.toggleEditModal(id)}
        >
          Инфо
        </button>
      ),
      delete: (
        <button
          className='btn-reset btn-link'
          onClick={this.deleteBranch(branch.id)}
        >
          <SVG src={trash} />
        </button>
      )
    }));
  }

  @bind
  deleteBranch(id) {
    return () => {
      if (window.confirm('Удалить филиал?')) {
        this.props.deleteBranch(id);
      }
    };
  }

  @bind
  pushHistory(query) {
    const { history } = this.props;
    history.push(
      `${history.location.pathname}?${queryString.stringify({
        ...getQueryString(),
        ...query
      })}`
    );
  }

  @bind
  handleSelect(name) {
    return value => {
      this.pushHistory({ [name]: value, page: 1 });
    };
  }

  @bind
  formatPartners() {
    return [
      { name: 'Все', value: '' },
      ...this.props.partners.map(partner => ({
        name: partner.shortName,
        value: partner.id
      }))
    ];
  }

  @bind
  getModalData() {
    const data = this.props.branches[this.props.values.editModalId];
    if (!data) return {};
    return {
      ...data,
      partnerId: data.partner.id
    };
  }

  render() {
    const { events, values } = this.props;
    const partnerId = getQueryString('partnerId');

    return (
      <React.Fragment>
        <div className='header'>
          <h2 className='h2'>Филиалы</h2>
        </div>
        <div className='page-actions'>
          <Select
            onChange={this.handleSelect('partnerId')}
            options={this.formatPartners()}
            placeholder='Партнёры'
            search
            value={partnerId}
          />
        </div>
        <div className='table-container'>
          <Table
            options={[
              { name: 'city', value: 'Города' },
              { name: 'address', value: 'Адрес' },
              { name: 'workTime', value: 'Рабочее время' },
              { name: 'phone', value: 'Телефон', className: 'table__nowrap' },
              { name: 'active', value: 'Доступен' },
              { name: 'info', value: '', className: 'modal__info' },
              { name: 'delete', value: '', className: 'modal__delete' }
            ]}
            rows={this.formatData()}
          />
        </div>
        <EditModal
          action={{
            name: 'Сохранить',
            onClick: this.props.changeBranch,
            className: 'btn-secondary'
          }}
          defaultState={this.getModalData()}
          edit
          onToggle={events.toggleEditModal}
          open={values.isOpenEditModal}
          title='Информация'
        />
        <Pagination
          limit={BRANCHES_LIMIT}
          total={this.props.pagination.total}
          value={events.getQueryString('page')}
        />
      </React.Fragment>
    );
  }
}

const Page = withPageContainer(BranchesPage, { limit: BRANCHES_LIMIT });

const mapStateToProps = state => ({
  branches: state.branches.items,
  isFetching: state.branches.isFetching,
  pagination: state.branches.pagination,
  partners: state.partners.items
});

const mapDispatchToProps = dispatch => ({
  addBranch: (...args) => dispatch(addBranch(...args)),
  deleteBranch: id => dispatch(deleteBranch(id, 'admin')),
  changeBranch: (data, id) => dispatch(changeBranch(data, id, 'admin')),
  fetch: query => dispatch(fetchBranches(query, 'admin')),
  getPartners: query => dispatch(fetchPartners(query))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Page));
