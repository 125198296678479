import React from 'react';
import InputMask from 'react-input-mask';

import Checkbox from '../../components/checkbox';
import Input from '../../components/oldInput';
import { withModalContainer } from '../../components/oldModal';

export default withModalContainer(({ values, events, edit }) => {
  const handleChangeComission = e => {
    let value = Number(e.target.value);

    if (value > 100) value = 100;
    if (value < 0) value = 0;

    events.defaultChangeHandler({ comission: value });
  };

  return (
    <>
      <div className='input-group'>
        <Input
          inputProps={{
            onChange: events.handleChange('lastName'),
            value: values.lastName
          }}
          label='Фамилия'
        />
        <Input
          inputProps={{
            onChange: events.handleChange('firstName'),
            value: values.firstName
          }}
          label='Имя'
        />
        <Input
          inputProps={{
            onChange: events.handleChange('middleName'),
            value: values.middleName
          }}
          label='Отчество'
        />
      </div>
      <Input
        inputProps={{
          onChange: events.handleChange('email'),
          value: values.email
        }}
        label='Email'
      />
      <Input
        inputComponent={InputMask}
        inputProps={{
          mask: '+7 (999) 999 99 99',
          maskChar: '',
          onChange: events.handleChange('phone'),
          value: values.phone
        }}
        label='Телефон'
      />
      <Input
        inputProps={{
          onChange: events.handleChange('password'),
          type: 'password',
          value: values.password
        }}
        label={edit ? '••••••' : 'Пароль'}
      />
      <Input
        inputProps={{
          onChange: handleChangeComission,
          type: 'number',
          value: values.comission
        }}
        label='Комиссия'
      />
      {edit && (
        <Checkbox
          className='form-control'
          label='Доступен'
          onChange={events.handleCheck('active')}
          value={values.active}
        />
      )}
    </>
  );
});
