import React from 'react';
import InputMask from 'react-input-mask';

import './style.scss';

const SearchButton = props => {
  const handleSubmit = e => {
    e.preventDefault();
    props.btnProps.onClick();
  };

  return (
    <form
      className='search-btn'
      id={props.id}
      onSubmit={handleSubmit}
      style={props.style}
    >
      <div className='input'>
        <InputMask
          {...props.inputProps}
          className={`search-btn__input ${props.inputProps.className || ''}`}
        />
      </div>
      <button
        form={props.id}
        type='submit'
        {...props.btnProps}
        className={`btn search-btn__btn ${props.btnProps.className || ''}`}
      >
        {props.btnProps.title}
      </button>
    </form>
  );
};

export default SearchButton;
