import React from 'react';
import SVG from 'react-svg';
import ReactTooltip from 'react-tooltip'

import './style.scss';

import activateIcon from './icons/activate.svg';
import deleteIcon from './icons/delete.svg';
import editIcon from './icons/edit.svg';
import infoIcon from './icons/info.svg';
import banIcon from './icons/ban.svg';

const icons = {
  activate: activateIcon,
  delete: deleteIcon,
  edit: editIcon,
  info: infoIcon,
  ban: banIcon
};

class TableActions extends React.Component {
  static defaultProps = {
    onClick: () => false
  };

  @bind
  getItemClassName({ className, disabled }) {
    let cn = 'Actions-item';
    if (className) cn += ` ${className}`;
    if (disabled) cn += ' disabled';

    return cn;
  }

  getItemOnClick({ onClick, disabled }) {
    if (disabled) return () => {};
    return onClick || (() => {});
  }

  @bind
  getItemProps(item, index) {
    const { tip } = item;

    const prop = {
      className: this.getItemClassName(item),
      onClick: this.getItemOnClick(item)
    };

    if (tip) {
      prop['data-tip'] = tip;
    }

    return prop;
  }

  render() {
    const { items, tooltipProps } = this.props;

    return (
      <div className='Actions'>
        {items.map((item, index) => (
          <div
            key={item.icon || index}
            {...this.getItemProps(item, index)}
          >
            {item.icon ? <SVG src={icons[item.icon]} /> : item.content || null}
          </div>
        ))}
        <ReactTooltip {...tooltipProps} />
      </div>
    );
  }
}

export default TableActions;
