import queryString from 'query-string';

import {
  URL,
  REQUEST_SELLERS,
  RECEIVE_SELLERS,
  SELLERS_LIMIT
} from '../lib/actions';

import { getQueryParametres } from '../lib/functions';

import { logout } from './user';

function requestSellers() {
  return {
    type: REQUEST_SELLERS
  };
}

function receiveSellers(response) {
  return {
    type: RECEIVE_SELLERS,
    response
  };
}

export function fetchSellers(query = { page: 1, limit: 7 }) {
  return async (dispatch, getState) => {
    dispatch(requestSellers());
    const request = await fetch(
      `${URL}/partner/sellers?${getQueryParametres(query)}`,
      {
        method: 'GET',
        headers: {
          'x-access-token': getState().user.token
        }
      }
    );
    const { status } = await request;
    if (status === 200) {
      const response = await request.json();
      dispatch(receiveSellers(response));
    } else {

      if (status === 401) {
        dispatch(logout());
      }
    }
  };
}

export function addSeller(data) {
  return async (dispatch, getState) => {
    const request = await fetch(`${URL}/partner/sellers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getState().user.token
      },
      body: JSON.stringify(data)
    });
    const { status } = await request;
    if (status === 200) {
      dispatch(
        fetchSellers({
          ...queryString.parse(window.location.search),
          limit: SELLERS_LIMIT
        })
      );
    } else {
      switch (status) {
        case 400:
          alert('Заполните все поля');
          break;

        default:

          break;
      }
    }
    return status;
  };
}

export function changeSeller(data, id) {
  return async (dispatch, getState) => {
    const request = await fetch(`${URL}/partner/sellers/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getState().user.token
      },
      body: JSON.stringify(data)
    });
    const { status } = await request;
    if (status === 200) {
      dispatch(
        fetchSellers({
          ...queryString.parse(window.location.search),
          limit: SELLERS_LIMIT
        })
      );
    } else {
      switch (status) {
        case 400:
          alert('Заполните все поля');
          break;

        default:

          break;
      }
    }
    return status;
  };
}
