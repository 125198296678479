import queryString from 'query-string';

import { getQueryParametres } from '../lib/functions';

import { URL } from '../lib/actions';

import { logout } from './user';

export function getUpload(name, additionalQuery) {
  return async (dispatch, getState) => {
    const query = {
      limit: 10,
      ...queryString.parse(window.location.search),
      ...additionalQuery
    };
    const request = await fetch(
      `${URL}/${name}/export.csv?${getQueryParametres(query)}`,
      {
        method: 'GET',
        headers: {
          'x-access-token': getState().user.token
        }
      }
    );
    const { status } = await request;

    if (status === 200) {
      const response = await request.blob();
      const href = window.URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = href;
      link.type = 'text/csv';
      link.download = `${name}.csv`;
      link.click();

      return;
    }
    if (status === 401) {
      dispatch(logout());
    } else if (status === 404) {
      alert('Данных с учетом выбранных фильтров не найдено');
    } else {
    }
  };
}
