import React from 'react';
import SVG from 'react-svg';

import loader from '../../image/loader.svg';
import './style.scss';

export default function(Component, isFetching) {
  return class extends React.Component {
    render() {
      return (
        <div style={{ position: 'relative' }}>
          {isFetching && (
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%'
              }}
            >
              <SVG
                className='loader'
                src={loader}
              />
            </div>
          )}
          <Component {...this.props} />
        </div>
      );
    }
  };
}
