export const URL = process.env.REACT_APP_API_URL + '/api';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const GET_PARTNER_INFO = 'GET_PARTNER_INFO';

export const REQUEST_PARTNERS = 'REQUEST_PARTNERS';
export const RECEIVE_PARTNERS = 'RECEIVE_PRATNERS';
export const PARTNERS_LIMIT = 10;

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const DELETE_ADMIN_PRODUCT = 'DELETE_ADMIN_PRODUCT';
export const PRODUCTS_LIMIT = 10;

export const REQUEST_BRANCHES = 'REQUEST_BRANCHES';
export const RECEIVE_BRANCHES = 'RECEIVE_BRANCHES';
export const BRANCHES_LIMIT = 10;

export const REQUEST_SELLERS = 'REQUEST_SELLERS';
export const RECEIVE_SELLERS = 'RECEIVE_SELLERS';
export const SELLERS_LIMIT = 10;

export const REQUEST_SALES = 'REQUEST_SALES';
export const RECEIVE_SALES = 'RECEIVE_SALES';
export const SALES_LIMIT = 10;

export const REQUEST_CITIES = 'REQUEST_CITIES';
export const RECEIVE_CITIES = 'RECEIVE_CITIES';
export const CITIES_LIMIT = 10;

export const REQUEST_CLIENTS = 'REQUEST_CLIENTS';
export const RECEIVE_CLIENTS = 'RECEIVE_CLIENTS';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const CLIENTS_LIMIT = 10;

export const REQUEST_ADMIN_SALES = 'REQUEST_ADMIN_SALES';
export const RECEIVE_ADMIN_SALES = 'RECEIVE_ADMIN_SALES';
export const ADMIN_SALES_LIMIT = 10;

export const REQUEST_BONUSES = 'REQUEST_BONUSES';
export const RECEIVE_BONUSES = 'RECEIVE_BONUSES';
export const GET_TRANSACTION_LIMIT = 'GET_TRANSACTION_LIMIT';
export const BONUSES_LIMIT = 10;

// USER

export const CLEAR_PARTNER_INFO = 'CLEAR_PARTNER_INFO';

export const clearPartnerInfoAction = () => ({
  type: CLEAR_PARTNER_INFO
});

// SETTINGS

export const SET_CASHBACK = 'SET_CASHBACK';
export const GET_SETTINGS = 'GET_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_SUBJECT = 'UPDATE_SUBJECT';
export const GET_SUBJECTS = 'GET_SUBJECTS';

export const getSubjectsAction = response => ({
  type: GET_SUBJECTS,
  response
});

export const setCashBackAction = () => ({
  type: SET_CASHBACK
});

export const getSettingsAction = response => ({
  type: GET_SETTINGS,
  response
});

export const updateSettingsAction = () => ({
  type: UPDATE_SETTINGS
});

export const updateSubjectSettingsAction = () => ({
  type: UPDATE_SUBJECT
});

// PROMOCODES

export const GET_PROMOCODES_GROUP_BY_ID = 'GET_PROMOCODES_GROUP_BY_ID';
export const POST_PROMOCODES_GROUP = 'POST_PROMOCODES_GROUP';
export const GET_PROMOCODES_GROUPS = 'GET_PROMOCODES_GROUPS';
export const PUT_PROMOCODES_GROUP = 'PUT_PROMOCODES_GROUP';
export const GENERATE_PROMOCODE = 'GENERATE_PROMOCODE';
export const DELETE_PROMOCODE_GROUP = 'DELETE_PROMOCODE_GROUP';

export const getPromocodesGroupsAction = response => ({
  type: GET_PROMOCODES_GROUPS,
  response
});

export const getPromocodesGroupByIdAction = response => ({
  type: GET_PROMOCODES_GROUP_BY_ID,
  response
});

export const postPromocodesGroupAction = response => ({
  type: POST_PROMOCODES_GROUP,
  response
});

export const putPromocodesGroupAction = response => ({
  type: PUT_PROMOCODES_GROUP,
  response
});

export const generatePromocodeAction = response => ({
  type: GENERATE_PROMOCODE,
  response
});

export const deletePromocodeGroupAction = id => ({
  type: DELETE_PROMOCODE_GROUP,
  response: { id }
});

export const getTransactionLimitAction = response => ({
  type: GET_TRANSACTION_LIMIT,
  response
});
