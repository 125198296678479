import {
  getSettingsAction,
  getSubjectsAction,
  getTransactionLimitAction,
  setCashBackAction,
  updateSettingsAction,
  updateSubjectSettingsAction
} from '../lib/actions';
import request from '../lib/request';

export const setCashBack = cashback => async dispatch => {
  const success = () => {
    dispatch(setCashBackAction());
  };

  await request('/admin/cashback', {
    method: 'POST',
    success,
    body: JSON.stringify({ cashback })
  });
};

export const updateSettings = settings => async dispatch => {
  const success = () => {
    dispatch(updateSettingsAction());
  };

  await request('/admin/settings', {
    method: 'PUT',
    success,
    body: JSON.stringify(settings)
  });
};

export const getSubjects = () => async dispatch => {
  const success = response => {
    dispatch(getSubjectsAction(response));
  };

  await request('/admin/settings/subject/subjects', {
    success
  });
};

export const getSettings = () => async dispatch => {
  let data = {};

  const success = response => {
    data = { ...data, ...response.data };
  };

  await Promise.all([
    request('/admin/cashback', {
      success
    }),
    request('/admin/settings', {
      success
    }),
    request('/admin/settings/subject/types?incorrectSubject=true', {
      success: response =>
        (data = { ...data, incorrectSubjects: response.data })
    }),
    request('/admin/settings/subject/types', {
      success: response => (data = { ...data, types: response.data })
    }),
    dispatch(getSubjects())
  ]);

  dispatch(getSettingsAction(data));
};

export const updateSubjectSettings = (body, done) => async dispatch => {
  const success = () => {
    dispatch(updateSubjectSettingsAction());
  };

  await request('/admin/settings/subject', {
    body: JSON.stringify(body),
    method: 'PUT',
    success,
    done
  });
};

const done = (dispatch, callback) => (response, status) => {
  if (status === 200) {
    if (callback) {
      callback(response, status);
    }

    dispatch(getSubjects());
  } else {
    alert(response.message);
  }
};

export const createSubject = (subject, callback) => async dispatch => {
  await request('/admin/settings/subject/subjects', {
    body: JSON.stringify({ subject }),
    method: 'POST',
    done: done(dispatch, callback)
  });
};

export const updateSubject = (
  subject,
  newSubject,
  callback
) => async dispatch => {
  await request('/admin/settings/subject/subjects', {
    body: JSON.stringify({ subject, newSubject }),
    method: 'PUT',
    done: done(dispatch, callback)
  });
};

export const deleteSubject = subject => async dispatch => {
  await request('/admin/settings/subject/subjects', {
    body: JSON.stringify({ subject }),
    method: 'DELETE',
    done: done(dispatch)
  });
};

export const getTransactionLimit = () => async dispatch => {
  const success = response => {
    dispatch(getTransactionLimitAction(response));
  };
  await request('/admin/bonuses/limit', {
    success
  });
};

export const setTransactionLimit = value => async () => {
  await request('/admin/bonuses/limit', {
    method: 'POST',
    body: JSON.stringify({ value })
  });
};

export const setBonusDateEljur = (from, to) => async () => {
  await request('/admin/bonuses/rating', {
    method: 'GET',
    query: {
      from: `${from}`,
      to: `${to}`,
      type: 'user_all',
      source: 'eljur_nn'
    },
    isBlob: true,
    success: async (blobResult, status) => {
      if (status === 200) {
        try {
          const outputFilename = `Элжур-НН-${Date.now()}.xls`;
          // eslint-disable-next-line no-undef
          const url = URL.createObjectURL(new Blob([blobResult.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', outputFilename);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          throw Error(error);
        }
      }
    }
  });
};

export const setBonusDateDnevnik = (from, to) => async () => {
  await request('/admin/bonuses/rating', {
    method: 'GET',
    query: {
      from: `${from}`,
      to: `${to}`,
      type: 'user_all',
      source: 'dnevnik',
      address: 'Нижегородская область'
    },
    isBlob: true,
    success: async (blobResult, status) => {
      if (status === 200) {
        try {
          const outputFilename = `Дневник-Ру-${Date.now()}.xls`;
          // eslint-disable-next-line no-undef
          const url = URL.createObjectURL(new Blob([blobResult.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', outputFilename);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          throw Error(error);
        }
      }
    }
  });
};
