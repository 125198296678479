import { REQUEST_BRANCHES, RECEIVE_BRANCHES } from '../lib/actions';
import { BRANCHES, BRANCHES_PAGINATION } from '../lib/storageVariables';
import { getLocalStorage, setLocalStorage } from '../lib/functions';

const STATE = {
  isFetching: false,
  items: getLocalStorage(BRANCHES) || [],
  pagination: getLocalStorage(BRANCHES_PAGINATION) || []
};

export default function(state = STATE, action) {
  switch (action.type) {
    case REQUEST_BRANCHES:
      return {
        ...state,
        isFetching: true
      };

    case RECEIVE_BRANCHES:
      const branches = action.response.data;
      const { pagination } = action.response.meta;

      if (branches) setLocalStorage(BRANCHES, branches);
      if (pagination) setLocalStorage(BRANCHES_PAGINATION, pagination);

      return {
        ...state,
        isFetching: false,
        items: branches || state.items,
        pagination: pagination || state.pagination
      };

    case 'GET_SUGGESTIONS':
      return {
        ...state,
        suggestions: action.response.suggestions
      };

    default:
      return state;
  }
}
