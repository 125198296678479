import React from 'react';

import NumberFormat from 'react-number-format';

import ReactTooltip from 'react-tooltip';

import Checkbox from '../../components/checkbox';
import { withModalContainer } from '../../components/oldModal';
import Input from '../../components/oldInput';

const formatPercentage = value => {
  const v = parseInt(value, 10);
  if (v > 100) return '100';
  if (v < 0) return '0';
  if (!value) return '0';
  return value;
};

export default withModalContainer(({ values, events, edit }) => {
  const img = values.src || values.image;

  const handleChangeCashback = e => {
    let val = formatPercentage(e.target.value);
    val = val.replace(/%/g, '');
    events.defaultChangeHandler({ cashback: val });
  };

  let cashbackTip = 'Кешбек начисляется на разницу("Цена"-"скидка")';
  if (values.cashbackFromFullPrice)
    cashbackTip = 'Кешбек начисляется на всю сумму("Цена")';

  return (
    <>
      <Input
        inputProps={{
          onChange: events.handleChange('name'),
          value: values.name
        }}
        label='Название'
      />
      {img && (
        <div className='modal__photo-preview form-control'>
          <img
            alt='Лого'
            src={img}
          />
        </div>
      )}
      <button
        className='btn-primary btn_size_l form-control'
        onClick={events.handleLoadFile('image', 'partner')}
        type='button'
      >
        Загрузить изображение
      </button>
      <Input
        className='textarea'
        inputComponent='textarea'
        inputProps={{
          onChange: events.handleChange('description'),
          rows: '4',
          value: values.description
        }}
        label='Описание'
      />
      <Input
        inputProps={{
          onChange: events.handleChange('price'),
          value: values.price
        }}
        label='Цена'
      />
      <div data-tip={cashbackTip}>
        <Checkbox
          label='Кешбек на всю сумму'
          onChange={events.handleCheck('cashbackFromFullPrice')}
          value={values.cashbackFromFullPrice}
        />
        <ReactTooltip />
      </div>
      <Input
        inputComponent={NumberFormat}
        inputProps={{
          onChange: handleChangeCashback,
          suffix: '%',
          value: formatPercentage(values.cashback)
        }}
        label='Кэшбек'
      />
      <Input
        inputProps={{
          onChange: events.handleChange('discount'),
          value: values.discount
        }}
        label='Скидка'
      />
      <Input
        inputComponent={NumberFormat}
        inputProps={{
          onChange: events.handleChange('percentage'),
          suffix: '%',
          value: formatPercentage(values.percentage)
        }}
        label='Процент скидки'
      />
      <Checkbox
        label='Дневной лимит'
        onChange={events.handleCheck('dailyLimited')}
        value={values.dailyLimited}
      />
      {edit && (
        <Checkbox
          label='Доступен'
          onChange={events.handleCheck('active')}
          value={values.active}
        />
      )}
    </>
  );
});
