import React from 'react';
import { connect } from 'react-redux';

import { addBonuses, removeBonuses } from '../../actions/clients';
import Checkbox from '../../components/checkbox';
import SearchButton from '../../components/SearchButton';
import { withModalContainer } from '../../components/oldModal';
import Input from '../../components/oldInput';
import { parseInteger } from '../../lib/functions';

const handleUpdateBonuses = props => () => {
  props.update({
    clientId: props.values.id,
    amount: parseInt(props.values.addBonuses, 10),
    message: ''
  });
};

const handleRemoveBonuses = props => () => {
  props.remove({
    clientId: props.values.id,
    amount: parseInt(props.values.removeBonuses, 10),
    message: ''
  });
};

const Modal = withModalContainer(props => {
  const { values, events } = props;

  const changePositiveBonuses = e => {
      let val = parseInteger(e.target.value);
      if (Number(val) < 0) val = Math.abs(val);
      props.events.defaultChangeHandler({ addBonuses: val });
    };

  const changeNegativeBonuses = e => {
    const val = parseInteger(e.target.value);
    props.events.defaultChangeHandler({ removeBonuses: val });
  };

  return (
    <>
      <div className='input-group'>
        <Input
          inputProps={{
            value: values.lastName
          }}
          label='Фамилия'
        />
        <Input
          inputProps={{
            value: values.firstName
          }}
          label='Имя'
        />
        <Input
          inputProps={{
            value: values.middleName
          }}
          label='Отчество'
        />
      </div>
      <Input
        inputProps={{
          value: values.bonus
        }}
        label='Баланс'
      />
      <Input
        inputProps={{
          value: values.phone
        }}
        label='Телефон'
      />
      <SearchButton
        btnProps={{
          onClick: handleUpdateBonuses(props),
          className: 'btn-primary',
          title: 'Пополнить',
          type: 'button'
        }}
        inputProps={{
          placeholder: 'Пополнить баланс',
          maxLength: 6,
          onChange: changePositiveBonuses,
          value: values.addBonuses
        }}
      />
      <SearchButton
        btnProps={{
          onClick: handleRemoveBonuses(props),
          className: 'btn-primary',
          title: 'Списать',
          type: 'button'
        }}
        inputProps={{
          placeholder: 'Списать бонусы',
          maxLength: 6,
          onChange: changeNegativeBonuses,
          value: values.removeBonuses
        }}
      />
      <Checkbox
        label='Доступен'
        onChange={events.handleCheck('active')}
        value={values.active}
      />
    </>
  );
});

export default connect(
  null,
  dispatch => ({
    update: (...args) => dispatch(addBonuses(...args)),
    remove: (data, id) => dispatch(removeBonuses(data, id))
  })
)(Modal);
