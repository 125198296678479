import queryString from 'query-string';

import {
  URL,
  REQUEST_BRANCHES,
  RECEIVE_BRANCHES,
  BRANCHES_LIMIT
} from '../lib/actions';

import { getQueryParametres } from '../lib/functions';

import { logout } from './user';

function requestBranches() {
  return {
    type: REQUEST_BRANCHES
  };
}

function receiveBranches(response) {
  return {
    type: RECEIVE_BRANCHES,
    response
  };
}

export function fetchBranches(
  query = {
    page: 1,
    limit: 7
  },
  name = 'partner'
) {
  return async (dispatch, getState) => {
    dispatch(requestBranches());
    const request = await fetch(
      `${URL}/${name}/places?${getQueryParametres(query)}`,
      {
        method: 'GET',
        headers: {
          'x-access-token': getState().user.token
        }
      }
    );
    const { status } = await request;
    if (status === 200) {
      const response = await request.json();
      dispatch(receiveBranches(response));
    } else {
      if (status === 401) {
        dispatch(logout());
      }
    }
  };
}

const formatBody = data => {
  const result = { ...data };

  if (!result.lat) result.lat = null;
  if (!result.lon) result.lon = null;

  return result;
};

export function addBranch(data) {
  return async (dispatch, getState) => {
    if (!data.city && !data.allRussia) return alert('Укажите город');

    const request = await fetch(`${URL}/partner/places`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getState().user.token
      },
      body: JSON.stringify(formatBody(data))
    });
    const { status } = await request;
    const response = await request.json();
    if (status === 200) {
      dispatch(
        fetchBranches({
          ...queryString.parse(window.location.search),
          limit: BRANCHES_LIMIT
        })
      );
    } else {
      switch (status) {
        case 400:
          alert('Заполните все поля');
          break;

        default:
          alert(response.message);
          break;
      }
    }
    return status;
  };
}

export function deleteBranch(id, name = 'partner') {
  return async (dispatch, getState) => {
    const request = await fetch(`${URL}/${name}/places/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getState().user.token
      }
    });
    const { status } = await request;
    const response = await request.json();
    if (status === 200) {
      dispatch(
        fetchBranches(
          {
            ...queryString.parse(window.location.search),
            limit: BRANCHES_LIMIT
          },
          name
        )
      );
    } else {
      switch (status) {
        case 403:
          alert('У этого филиала есть товары или промокоды');
          break;

        default:
          alert(response.message);
          break;
      }
    }
    return status;
  };
}

export function changeBranch(data, id, name = 'partner') {
  return async (dispatch, getState) => {
    if (!data.city && !data.allRussia) return alert('Укажите город');

    const request = await fetch(`${URL}/${name}/places/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getState().user.token
      },
      body: JSON.stringify(formatBody(data))
    });
    const { status } = await request;
    const response = await request.json;
    if (status === 200) {
      dispatch(
        fetchBranches(
          {
            ...queryString.parse(window.location.search),
            limit: BRANCHES_LIMIT
          },
          name
        )
      );
    } else {
      switch (status) {
        case 400:
          alert('Заполните все поля');
          break;

        default:
          alert(response.message);
          break;
      }
    }
    return status;
  };
}
