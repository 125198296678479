import React from 'react';

import RangeSlider from '../components/RangeSlider';
import Checkbox from '../components/checkbox';
import { modalBind } from '../lib/functions';

const promocodeGroupModal = modal => {
  const bind = modalBind.bind(modal);

  const changeRangeSlider = name => value => {
    modal.setDataState({ [name]: value });
  };

  const { length, quantity, digits, letters } = modal.state.data;

  return (
    <>
      <RangeSlider
        label='Кол-во символов'
        max={100}
        min={6}
        onChange={changeRangeSlider('length')}
        value={length}
      />
      <div className='modal-group flex-start'>
        <Checkbox
          label='Цифры'
          onChange={modal.handleCheck('digits')}
          value={digits}
        />
        <Checkbox
          label='Буквы (A-Z)'
          onChange={modal.handleCheck('letters')}
          value={letters}
        />
      </div>
      <RangeSlider
        label='Кол-во промокодов'
        max={5000}
        onChange={changeRangeSlider('quantity')}
        value={quantity}
      />
    </>
  );
};

export default promocodeGroupModal;
