import request from '../lib/request';

export const uploadImage = (file, userType = 'admin') => async () => {
  const formData = new FormData();
  formData.append('image', file);

  let result = null;

  const success = response => {
    result = response;
  };

  await request(`/${userType}/upload/image`, {
    method: 'POST',
    body: formData,
    headers: {},
    success
  });

  return result;
};
