import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import Button from '../components/Button';

import MetaStat from '../components/MetaStat';

import Select from '../components/Select';

import Table from '../components/table';
import Modal from '../modals/partners-modal';
import withLoader from '../components/loader';
import Checkbox from '../components/checkbox';
import Pagination from '../components/oldPagination';
import SearchButton from '../components/SearchButton';

import ConfirmationModal from '../modals/confirmation-modal';

import {
  fetchPartners,
  addPartner,
  changePartner,
  uploadImage,
  restorePassword,
  searchPartners
} from '../actions/partners';

import { getQueryString } from '../lib/functions';

class PartnersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      openEditModalId: -1,
      isOpenEditModal: false,
      isOpenConfirmationModal: false,
      confirmationMessage: '',
      search: ''
    };
    this.fetchWithQuery();
  }

  componentDidUpdate(nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      this.fetchWithQuery();
    }
  }

  @bind
  fetchWithQuery() {
    this.props.fetchPartners({
      page: 1,
      ...getQueryString()
    });
  }

  @bind
  toggleModal() {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  @bind
  toggleEditModal(id) {
    this.setState({
      isOpenEditModal: !this.state.isOpenEditModal,
      editModalId: typeof id === 'number' ? id : -1 // при закрытии модалки id не передается
    });
  }

  @bind
  pushHistory(query) {
    const { history } = this.props;
    history.push(
      `${history.location.pathname}?${queryString.stringify({
        ...getQueryString(),
        ...query
      })}`
    );
  }

  @bind
  handleSelect(name) {
    return value => {
      this.pushHistory({
        [name]: value,
        page: 1
      });
    };
  }

  @bind
  handleChangeSearch(e) {
    this.setState({ search: e.target.value });
  }

  @bind
  handleSearch() {
    if (!this.state.search.length) {
      this.props.fetchPartners();
    } else {
      this.props.searchPartners({ query: this.state.search });
    }
  }

  @bind
  getType(typeNum) {
    const type = this.props.partnerTypes.find(item => item.id === typeNum);
    return type ? type.name : '-';
  }

  @bind
  formatData() {
    return this.props.partners.map(({ ...partner }, id) => ({
      ...partner,
      type: this.getType(partner.type),
      accessible: <Checkbox value={partner.accessible} />,
      active: <Checkbox value={partner.active} />,
      info: (
        <button
          className='btn-reset btn-link'
          onClick={() => this.toggleEditModal(id)}
        >
          Инфо
        </button>
      )
    }));
  }

  @bind
  openConfirmationModal(confirmationMessage) {
    this.setState({
      isOpenConfirmationModal: true,
      confirmationMessage
    });
  }

  @bind
  restorePassword(id) {
    this.props.restorePassword(id, this.openConfirmationModal);
  }

  @bind
  addPartner(data) {
    this.props.addPartner(data, this.openConfirmationModal);
  }

  @bind
  onCloseConfirmation() {
    this.setState({
      isOpenConfirmationModal: false,
      confirmationMessage: '',
      isModalOpen: false
    });
  }

  render() {
    const TableWithLoader = withLoader(Table, this.props.isFetching);
    const data = this.props.partners[this.state.editModalId] || {};
    const { sort = '', type = '', active = '' } = getQueryString();

    return (
      <div className='page-container wrapper'>
        <div className='header'>
          <h2 className='h2'>Партнеры</h2>
        </div>
        <div className='page-actions'>
          <Select
            onChange={this.handleSelect('sort')}
            options={[
              {
                name: 'По дате добавления (все)',
                value: ''
              },
              {
                name: 'По имени',
                value: 'fullName'
              },
              {
                name: 'По короткому имени',
                value: 'shortName'
              },
              {
                name: 'По доступности',
                value: 'active'
              },
              {
                name: 'По рейтингу',
                value: 'rating'
              }
            ]}
            placeholder='Отсортировать'
            value={sort}
          />
          <Select
            onChange={this.handleSelect('type')}
            options={[
              {
                name: 'Все',
                value: ''
              },
              ...this.props.partnerTypes.map(item => ({
                name: item.name,
                value: item.id
              }))
            ]}
            placeholder='Фильтр по типу'
            value={type}
          />
          <Select
            onChange={this.handleSelect('active')}
            options={[
              {
                name: 'Все',
                value: ''
              },
              {
                name: 'Достуные',
                value: 'true'
              },
              {
                name: 'Не доступные',
                value: 'false'
              }
            ]}
            placeholder='По доступности'
            style={{ maxWidth: '300px' }}
            value={active}
          />
          <Button
            label='Создать'
            onClick={this.toggleModal}
            style={{ marginLeft: 'auto' }}
          />
        </div>
        <div className='page-actions'>
          <SearchButton
            btnProps={{
              onClick: this.handleSearch,
              className: 'btn-primary',
              title: 'Найти'
            }}
            inputProps={{
              onChange: this.handleChangeSearch,
              placeholder: 'Поиск',
              value: this.state.search
            }}
          />
        </div>
        <div className='page-stats'>
          <MetaStat
            label='Всего партнеров'
            value={this.props.pagination.total}
          />
        </div>
        <div className='table-container'>
          <TableWithLoader
            options={[
              {
                name: 'shortName',
                value: 'Наименование'
              },
              {
                name: 'cashback',
                value: 'Кэшбек'
              },
              {
                name: 'type',
                value: 'Тип'
              },
              {
                name: 'accessible',
                value: 'Доступен'
              },
              {
                name: 'active',
                value: 'Активен'
              },
              {
                name: 'rating',
                value: 'Рейтинг'
              },
              {
                name: 'orderIndex',
                value: 'Сортировка'
              },
              {
                name: 'info',
                value: ''
              }
            ]}
            rows={this.formatData()}
          />
        </div>
        <Modal
          action={{
            name: 'Добавить',
            onClick: this.addPartner,
            className: 'btn-primary'
          }}
          addPhoto={this.props.uploadImage}
          defaultState={{
            src: '',
            fullName: '',
            shortName: '',
            description: '',
            type: '',
            logo: '',
            image: '',
            link: '',
            supportEmail: '',
            contacts: {
              email: '',
              phone: '',
              viber: '',
              skype: '',
              web: ''
            },
            email: '',
            maintainer: {
              name: '',
              email: ''
            },
            active: false,
            cashback: '',
            promocodesAllowed: false
          }}
          onToggle={this.toggleModal}
          open={this.state.isModalOpen}
          title='Добавление партнера'
          types={this.props.partnerTypes}
        />
        <Modal
          action={{
            name: 'Сохранить',
            onClick: this.props.changePartner,
            className: 'btn-secondary',
            defaultValues: {
              cashback: '0'
            }
          }}
          addPhoto={this.props.uploadImage}
          defaultState={{
            ...data,
            src: ''
          }}
          edit
          onToggle={this.toggleEditModal}
          open={this.state.isOpenEditModal}
          restorePassword={this.restorePassword}
          title='Информация'
          types={this.props.partnerTypes}
        />
        <ConfirmationModal
          isOpen={this.state.isOpenConfirmationModal}
          message={this.state.confirmationMessage}
          onClose={this.onCloseConfirmation}
        />
        <Pagination
          total={this.props.pagination.total}
          value={getQueryString('page')}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  partners: state.partners.items,
  isFetching: state.partners.isFetching,
  status: state.partners.status,
  pagination: state.partners.pagination,
  partnerTypes: state.partnerTypes.list
});

const mapDispatchToProps = dispatch => ({
  addPartner: (...args) => dispatch(addPartner(...args)),
  uploadImage: (...args) => dispatch(uploadImage(...args)),
  fetchPartners: (...args) => dispatch(fetchPartners(...args)),
  changePartner: (...args) => dispatch(changePartner(...args)),
  restorePassword: (...args) => dispatch(restorePassword(...args)),
  searchPartners: query => dispatch(searchPartners(query))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PartnersPage)
);
