import { REQUEST_SELLERS, RECEIVE_SELLERS } from '../lib/actions';
import { SELLERS, SELLERS_PAGINATION } from '../lib/storageVariables';
import { getLocalStorage, setLocalStorage } from '../lib/functions';

const STATE = {
  isFetching: false,
  items: getLocalStorage(SELLERS) || [],
  pagination: getLocalStorage(SELLERS_PAGINATION) || []
};

export default function(state = STATE, action) {
  switch (action.type) {
    case REQUEST_SELLERS:
      return {
        ...state,
        isFetching: true
      };

    case RECEIVE_SELLERS:
      const sellers = action.response.data;
      const { pagination } = action.response.meta;

      if (sellers) setLocalStorage(SELLERS, sellers);
      if (pagination) setLocalStorage(SELLERS_PAGINATION, pagination);

      return {
        ...state,
        isFetching: false,
        items: sellers || state.items,
        pagination: pagination || state.pagination
      };

    default:
      return state;
  }
}
