import React from 'react';
import queryString from 'query-string';
import classNames from 'classnames';

export default function(Component, options = {}) {
  return class extends React.Component {
    state = {
      isModalOpen: false,
      openEditModalId: -1,
      isOpenEditModal: false
    };

    @bind
    getQueryString(param) {
      const params = queryString.parse(this.props.location.search);
      return param ? params[param] : params;
    }

    @bind
    fetchWithQuery() {
      this.props.fetch({
        page: 1,
        ...this.getQueryString(),
        limit: options.limit || 10
      });
    }

    componentDidMount() {
      this.fetchWithQuery();
    }

    componentDidUpdate(nextProps) {
      if (this.props.location.search !== nextProps.location.search) {
        this.fetchWithQuery();
      }
    }

    @bind
    toggleModal() {
      this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    @bind
    toggleEditModal(id) {
      this.setState({
        isOpenEditModal: !this.state.isOpenEditModal,
        editModalId: typeof id === 'number' ? id : -1 // при закрытии модалки id не передается
      });
    }

    events = {
      toggleModal: this.toggleModal,
      getQueryString: this.getQueryString,
      toggleEditModal: this.toggleEditModal
    };

    render() {
      return (
        <div
          className={classNames('page-container wrapper', options.className)}
        >
          <Component
            {...this.props}
            events={this.events}
            values={this.state}
          />
        </div>
      );
    }
  };
}
