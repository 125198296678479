import { REQUEST_PRODUCTS, RECEIVE_PRODUCTS } from '../lib/actions';
import { PRODUCTS, PRODUCTS_PAGINATION } from '../lib/storageVariables';
import { getLocalStorage, setLocalStorage } from '../lib/functions';

const STATE = {
  isFetching: false,
  items: getLocalStorage(PRODUCTS) || [],
  pagination: getLocalStorage(PRODUCTS_PAGINATION) || []
};

export default function(state = STATE, action) {
  switch (action.type) {
    case REQUEST_PRODUCTS:
      return {
        ...state,
        isFetching: true
      };

    case RECEIVE_PRODUCTS:
      const products = action.response.data;
      const { pagination } = action.response.meta;

      if (products) setLocalStorage(PRODUCTS, products);
      if (pagination) setLocalStorage(PRODUCTS_PAGINATION, pagination);

      return {
        ...state,
        isFetching: false,
        items: products || state.items,
        pagination: pagination || state.pagination
      };

    default:
      return state;
  }
}
