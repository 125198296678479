import React, { Component } from 'react';
import './style.scss';

class Button extends Component {
  @bind
  getClassName() {
    let cn = 'button';

    const { variant, loading } = this.props;
    if (['cancel', 'neutral', 'border'].includes(variant))
      cn += ` variant-${variant}`;

    if (loading) cn += ' loading';

    return cn;
  }

  render() {
    const { style, label, ...props } = this.props;

    return (
      <div
        className={this.getClassName()}
        style={style}
      >
        <button {...props}>{label}</button>
      </div>
    );
  }
}

export default Button;
