import queryString from 'query-string';

import { URL, REQUEST_SALES, RECEIVE_SALES, SALES_LIMIT } from '../lib/actions';

import { getQueryParametres } from '../lib/functions';

import { logout } from './user';

function requestSales() {
  return {
    type: REQUEST_SALES
  };
}

function receiveSales(response) {
  return {
    type: RECEIVE_SALES,
    response
  };
}

export function fetchSales(
  query = {
    page: 1,
    limit: 7
  }
) {
  return async (dispatch, getState) => {
    dispatch(requestSales());
    const request = await fetch(
      `${URL}/partner/sales?${getQueryParametres(query)}`,
      {
        method: 'GET',
        headers: {
          'x-access-token': getState().user.token
        }
      }
    );
    const { status } = await request;
    if (status === 200) {
      const response = await request.json();
      dispatch(receiveSales(response));
    } else {

      if (status === 401) {
        dispatch(logout());
      }
    }
  };
}

export function revert(id) {
  return async (dispatch, getState) => {
    const request = await fetch(`${URL}/partner/sales/${id}/revert`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getState().user.token
      }
    });
    const { status } = await request;
    if (status === 200) {
      dispatch(
        fetchSales({
          ...queryString.parse(window.location.search),
          limit: SALES_LIMIT
        })
      );
    } else {
      switch (status) {
        case 403:
          alert(
            'Эту продажу нельзя отменить (она была отменена или вы пытаетесь отменить транзакцию-отмену)'
          );
          break;

        case 404:
          alert('Продажа не найдена (не принадлежит текущему партнеру?)');
          break;

        default:

          break;
      }
    }
    return status;
  };
}
