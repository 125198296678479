import React, { Component } from 'react';

import ReactRangeSlider from 'react-rangeslider';

import './style.scss';
import Input from '../Input';

class RangeSlider extends Component {
  @bind
  handleChange(e) {
    const { max = 100, min = 0 } = this.props;
    let { value } = e.target;
    if (parseInt(value, 10) > max) value = max;
    if (parseInt(value, 10) < min) value = min;
    this.props.onChange(value);
  }

  render() {
    const { value, onChange, label, max = 100, min = 0 } = this.props;

    return (
      <div className='range-slider'>
        <p className='range-slider-label'>{label}</p>
        <div>
          <Input
            onChange={this.handleChange}
            value={value || min}
          />
          <ReactRangeSlider
            max={max}
            min={min}
            onChange={onChange}
            tooltip={false}
            value={value}
          />
        </div>
      </div>
    );
  }
}

export default RangeSlider;
