import queryString from 'query-string';

import {
  URL,
  REQUEST_ADMIN_SALES,
  RECEIVE_ADMIN_SALES,
  ADMIN_SALES_LIMIT
} from '../lib/actions';

import { getQueryParametres } from '../lib/functions';

import { logout } from './user';

function requestSales() {
  return {
    type: REQUEST_ADMIN_SALES
  };
}

function receiveSales(response) {
  return {
    type: RECEIVE_ADMIN_SALES,
    response
  };
}

export function fetchSales(
  query = {
    page: 1,
    limit: 7
  }
) {
  return async (dispatch, getState) => {
    dispatch(requestSales());
    const request = await fetch(
      `${URL}/admin/sales?${getQueryParametres(query)}`,
      {
        method: 'GET',
        headers: {
          'x-access-token': getState().user.token
        }
      }
    );
    const { status } = await request;
    const response = await request.json();
    if (status === 200) {
      dispatch(receiveSales(response));
    } else {
      switch (status) {
        case 401:
          dispatch(logout());
          break;

        case 422:
          alert(response.message);
          break;

        default:

          break;
      }
    }
  };
}

export function revert(id) {
  return async (dispatch, getState) => {
    const request = await fetch(`${URL}/admin/sales/${id}/revert`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getState().user.token
      }
    });
    const { status } = await request;
    if (status === 200) {
      dispatch(
        fetchSales({
          ...queryString.parse(window.location.search),
          limit: ADMIN_SALES_LIMIT
        })
      );
    } else {
      switch (status) {
        case 403:
          alert(
            'Эту продажу нельзя отменить (она была отменена или вы пытаетесь отменить транзакцию-отмену)'
          );
          break;

        default:

          break;
      }
    }
    return status;
  };
}
