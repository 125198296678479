import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import MetaStat from '../components/MetaStat';

import Table from '../components/table';
import Pagination from '../components/oldPagination';
import Select from '../components/Select';
import Datepicker from '../components/RangeDatePicker';

import { fetchSales, revert } from '../actions/admin-sales';
import { fetchPartners } from '../actions/partners';
import { getUpload } from '../actions/export';

import { ADMIN_SALES_LIMIT } from '../lib/actions';

import withPageContainer from '../components/page';
import {
  getQueryString,
  formatPhone,
  formatPhoneForQuery,
  formatDateToOutput
} from '../lib/functions';
import SearchButton from '../components/SearchButton';

class SalesPage extends React.Component {
  state = {
    clientPhone: ''
  };

  componentDidMount() {
    this.props.fetchPartners({ limit: 9999 });
  }

  @bind
  pushHistory(query) {
    const { history } = this.props;
    history.push(
      `${history.location.pathname}?${queryString.stringify({
        ...getQueryString(),
        ...query
      })}`
    );
  }

  @bind
  handleSelect(name) {
    return value => {
      this.pushHistory({
        [name]: value,
        page: 1
      });
    };
  }

  @bind
  handleChangePhone(event) {
    const value = formatPhone(event.target.value);
    this.setState({ clientPhone: value });
  }

  @bind
  handleSearchByPhone() {
    const value =
      this.state.clientPhone.length !== 18 ? '' : this.state.clientPhone;
    this.pushHistory({
      clientPhone: formatPhoneForQuery(value),
      page: 1
    });
  }

  @bind
  formatData() {
    return this.props.sales.map(sale => ({
      ...sale,
      name: sale.partner.name,
      phone: sale.client.phone,
      createdAt: formatDateToOutput(sale.createdAt),
      info: (
        <button
          className='btn-reset btn-link'
          disabled={!sale.revertable}
          onClick={() => this.props.revert(sale.id)}
        >
          {sale.revertable ? 'Возврат' : 'Возврат недоступен'}
        </button>
      ),
      totalSum: sale.revenue
    }));
  }

  @bind
  formatPartners() {
    return this.props.partners.map(partner => ({
      name: partner.shortName,
      value: partner.id
    }));
  }

  @bind
  handleChangeDate(from, to) {
    this.pushHistory({
      from,
      to,
      page: 1
    });
  }

  render() {
    const { events } = this.props;
    const { clientPhone = '', partnerId } = queryString.parse(
      window.location.search
    );

    return (
      <React.Fragment>
        <div className='header'>
          <h2 className='h2'>Транзакции</h2>
          <button
            className='btn-secondary header__button'
            onClick={() => this.props.getUpload('admin/sales')}
          >
            Экспорт
          </button>
        </div>
        <div className='page-actions'>
          <Select
            onChange={this.handleSelect('partnerId')}
            options={this.formatPartners()}
            placeholder='Партнёры'
            search
            value={partnerId}
          />
        </div>
        <div className='page-actions'>
          <SearchButton
            btnProps={{
              onClick: this.handleSearchByPhone,
              className: 'btn-primary',
              title: 'Найти'
            }}
            id='phone-search'
            inputProps={{
              mask: '+7 (999) 999 99 99',
              maskChar: '',
              onChange: this.handleChangePhone,
              placeholder: 'Поиск по телефону клиента',
              value: this.state.clientPhone || formatPhone(clientPhone)
            }}
          />
          <div className='control-block__datepicker'>
            <Datepicker onChange={this.handleChangeDate} />
          </div>
        </div>
        <div className='page-stats'>
          <MetaStat
            label='Всего бонусов'
            value={this.props.stats.totalBonus}
          />
          <MetaStat
            label='Всего рублей'
            value={this.props.stats.totalRevenue}
          />
          <MetaStat
            label='Уникальные клиенты'
            value={this.props.stats.totalClients}
          />
          <MetaStat
            label='Всего продаж'
            value={this.props.stats.totalSales}
          />
          <MetaStat
            label='Всего партнеров'
            value={this.props.stats.totalPartners}
          />
        </div>
        <div className='table-container'>
          <Table
            options={[
              {
                name: 'name',
                value: 'Партнёр'
              },
              {
                name: 'products',
                value: 'Товары / Услуги'
              },
              {
                name: 'amount',
                value: 'Бонусы'
              },
              {
                name: 'totalSum',
                value: 'Выручка'
              },
              {
                name: 'phone',
                value: 'Пользователь'
              },
              {
                name: 'createdAt',
                value: 'Дата создания'
              },
              {
                name: 'info',
                value: '',
                className: 'modal__info'
              }
            ]}
            rows={this.formatData()}
          />
        </div>
        <Pagination
          limit={ADMIN_SALES_LIMIT}
          total={this.props.pagination.total}
          value={events.getQueryString('page')}
        />
      </React.Fragment>
    );
  }
}

const Page = withPageContainer(SalesPage, { limit: ADMIN_SALES_LIMIT });

const mapStateToProps = state => ({
  sales: state.adminSales.items,
  isFetching: state.adminSales.isFetching,
  pagination: state.adminSales.pagination,
  stats: state.adminSales.stats,
  sellers: state.sellers.items,
  places: state.branches.items,
  cities: state.cities.items,
  partners: state.partners.items
});

const mapDispatchToProps = dispatch => ({
  revert: (...args) => dispatch(revert(...args)),
  fetch: (...args) => dispatch(fetchSales(...args)),
  getUpload: (...args) => dispatch(getUpload(...args)),
  fetchPartners: (...args) => dispatch(fetchPartners(...args))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Page)
);
