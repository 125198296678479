import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import RichTextEditor from 'react-rte/lib/RichTextEditor';

import { uploadImage } from '../actions/images';
import { fetchPartners } from '../actions/partners';
import Pagination from '../components/Pagination';

import {
  deletePromocodeGroup,
  getPromocodesGroups,
  offPromocodeGroup,
  postPromocodesGroup,
  putPromocodesGroup
} from '../actions/promocodes';

import MetaStat from '../components/MetaStat';
import Modal from '../components/Modal';
import TableActions from '../components/TableActions';
import renderModalContent from '../modals/promocodesModal';

import Table from '../components/table';
import Button from '../components/Button';
import Select from '../components/Select';
import Input from '../components/Input';
import RangeDatePicker from '../components/RangeDatePicker';

import {
  getQueryString,
  pushHistory,
  shouldFetch,
  withConfirm
} from '../lib/functions';

class PromocodesPage extends Component {
  state = { modalOpen: false, editModalOpen: false, dataId: -1 };

  componentDidMount() {
    this.fetchWithQuery();

    if (this.userType === 'admin') this.props.fetchPartners({ limit: 9999 });
  }

  componentDidUpdate(prevProps) {
    if (this.shouldFetch(prevProps)) this.fetchWithQuery();
  }

  userType = this._getUserType();

  shouldFetch = shouldFetch.bind(this);

  pushHistory = pushHistory.bind(this);

  @bind
  _getUserType() {
    return this.props.location.pathname.includes('admin') ? 'admin' : 'partner';
  }

  @bind
  fetchWithQuery() {
    this.props.getPromocodesGroups(getQueryString(), this.userType);
  }

  getTableOptions = () => {
    const options = [
      {
        name: 'name',
        value: 'Наименование'
      },
      {
        name: 'partner',
        value: 'Партнер'
      },
      {
        name: 'period',
        value: 'Период'
      },
      {
        name: 'status',
        value: 'Статус'
      },
      {
        name: 'total',
        value: 'Всего кодов'
      },
      {
        name: 'sale',
        value: 'Процент скидки'
      },
      {
        name: 'sold',
        value: 'Продано'
      },
      {
        name: 'notSold',
        value: 'Не продано'
      },
      {
        name: 'actions',
        value: ''
      }
    ];

    if (this.userType === 'partner') {
      delete options[1];
    }

    return options;
  };

  _getPeriod(from, to) {
    if (!from || !to) return 'Постоянно';

    const formatDate = date => moment(date).format('DD.MM.YYYY');

    return `с ${formatDate(from)} до ${formatDate(to)}`;
  }

  _getLink(id, off) {
    if (this.userType === 'admin') return `/admin/promocodes/${id}?off=${off}`;
    return `/promocodes/${id}?off=${off}`;
  }

  @bind
  confirmRequest(data, id) {
    const body = { ...data, active: !data.active };
    if (!body.active) body.review = false;
    this.props.putPromocodesGroup(body, id, this.userType);
  }

  @bind
  requestConfirm(data, id) {
    const body = { ...data, review: !data.review };
    this.props.putPromocodesGroup(body, id, this.userType);
  }

  @bind
  offGroup(data, id) {
    if (this.userType === 'admin') {
      const body = { ...data, off: true, review: false, active: false };
      this.props.putPromocodesGroup(body, id, 'admin');
    } else {
      this.props.offPromocodeGroup(id, 'partner');
    }
  }

  getTableActionItems(group, i) {
    const confirm =
      this.userType === 'admin' ? this.confirmRequest : this.requestConfirm;
    const active = this.userType === 'admin' ? group.active : group.review;
    let activateTip = this.userType === 'admin' ? 'Принять' : 'На согласование';
    if (active) activateTip = 'Снять с публикации';

    const items = [
      {
        icon: 'ban',
        onClick: () =>
          withConfirm(this.offGroup, 'Снять группу промокодов?')(
            group,
            group.id
          ),
        className: group.off ? 'active' : '',
        disabled: group.off,
        tip: 'Снять группу'
      },
      {
        icon: 'activate',
        onClick: () => confirm(group, group.id),
        className: active ? 'active' : '',
        disabled: group.off,
        tip: activateTip
      },
      {
        icon: 'edit',
        onClick: () => this.toggleEditModal(i),
        tip: 'Редактировать'
      }
    ];

    if (this.userType === 'partner') {
      items.unshift({
        icon: 'delete',
        onClick: () =>
          window.confirm('Удалить группу промокодов?') &&
          this.props.deletePromocodeGroup(group.id),
        tip: 'Удалить группу'
      });
    }

    return items;
  }

  getGroupStatus = ({ active, review, off }) => {
    if (off) return 'Снят';
    if (active && review) return 'Активен';
    if (review) return 'На согласовании';
    return 'Группа создана';
  };

  @bind
  getTablePartner(partnerId) {
    if (partnerId) {
      const match = this.props.partners.find(
        partner => partner.id === partnerId
      );
      if (match && match.shortName) return match.shortName;
    }

    return '';
  }

  getTableRows = () =>
    this.props.promocodesGroups.map((group, i) => ({
      ...group,
      partner: this.getTablePartner(group.partnerId),
      name: (
        <Link
          className='link'
          to={this._getLink(group.id, group.off)}
        >
          {group.name}
        </Link>
      ),
      period: this._getPeriod(group.from, group.to),
      status: this.getGroupStatus(group),
      sale: `${group.sale}%`,
      notSold: group.total - group.sold,
      actions: (
        <TableActions
          items={this.getTableActionItems(group, i)}
          tooltipProps={{
            effect: 'solid',
            place: 'top',
            type: 'dark'
          }}
        />
      )
    }));

  getStatusOptions = () => {
    if (this.userType === 'admin') {
      return [
        {
          name: 'Все',
          value: ''
        },
        {
          name: 'Активный',
          value: 'active_true'
        },
        {
          name: 'На согласовании',
          value: 'active_false'
        },
        {
          name: 'Снят',
          value: 'off_true'
        }
      ];
    }

    return [
      {
        name: 'Все',
        value: ''
      },
      {
        name: 'Активный',
        value: 'active_true'
      },
      {
        name: 'На согласовании',
        value: 'review_true'
      },
      {
        name: 'Снят',
        value: 'off_true'
      }
    ];
  };

  getStatusValue = () => {
    const { active, review, off } = getQueryString();
    if (review) return 'review_true';
    if (active) return `active_${active}`;
    if (off) return 'off_true';
    return '';
  };

  @bind
  filter(name) {
    return value => {
      const queryVal = getQueryString(name);
      let val = value;
      if (queryVal === value) val = '';
      this.pushHistory({ [name]: val, page: '' });
    };
  }

  @bind
  filterName(e) {
    this.pushHistory({ search: e.target.value, page: '' });
  }

  @bind
  filterDate(from, to) {
    this.pushHistory({ from, to, page: '' });
  }

  @bind
  filterStatus(value) {
    const [prop, val] = value.split('_');

    const query = {
      off: '',
      active: '',
      review: '',
      page: '',
      [prop]: val
    };

    if (prop === 'review') query.active = 'false';

    this.pushHistory(query);
  }

  @bind
  formatPartners() {
    return [
      {
        name: 'Все',
        value: ''
      },
      ...this.props.partners.map(partner => ({
        name: partner.shortName,
        value: partner.id
      }))
    ];
  }

  @bind
  toggleEditModal(id) {
    this.setState({ editModalOpen: !this.state.editModalOpen, dataId: id });
  }

  @bind
  toggleModal() {
    this.setState({ modalOpen: !this.state.modalOpen });
  }

  @bind
  onCloseModal(data, type) {
    const callback = (response, status) => {
      if (status === 200)
        this.setState({ modalOpen: false, editModalOpen: false });
    };

    const submitData = { ...data };
    if (submitData.indefinitely) {
      submitData.from = null;
      submitData.to = null;
    } else {
      submitData.from = moment(submitData.from || undefined).toISOString();
      submitData.to = moment(submitData.to || undefined).toISOString();
    }
    delete submitData.indefinitely;

    if (type === 'delete') submitData.active = false;

    if (submitData.description && submitData.description.toString) {
      submitData.description = submitData.description
        .toString('html')
        .replace(/\n/gm, '')
        .replace(/<p>/gm, '')
        .replace(/<\/p>/gm, '<br>');
    }

    if (submitData.id)
      this.props.putPromocodesGroup(
        submitData,
        submitData.id,
        this.userType,
        callback
      );
    else this.props.postPromocodesGroup(submitData, this.userType, callback);
  }

  @bind
  getModalData() {
    return {
      src: '',
      data: {
        name: '',
        description: RichTextEditor.createEmptyValue(),
        indefinitely: false,
        from: '',
        to: '',
        sale: '0',
        price: '0',
        image: ''
      }
    };
  }

  @bind
  getEditModalData() {
    const { promocodesGroups } = this.props;
    const { dataId } = this.state;
    if (!promocodesGroups[dataId]) return { src: '', data: {} };

    const data = {
      ...this.getModalData(),
      ...promocodesGroups[dataId],
      indefinitely: Boolean(
        !promocodesGroups[dataId].from && !promocodesGroups[dataId].to
      )
    };

    if (data.description) {
      data.description = RichTextEditor.createValueFromString(
        data.description,
        'html'
      );
    } else {
      data.description = '';
    }

    return {
      src: '',
      data
    };
  }

  @bind
  getEditModalTitle() {
    if (this.userType === 'admin') return 'Инфо';
    return 'Редакторовать';
  }

  @bind
  renderStatusFilter() {
    return (
      <Select
        onChange={this.filterStatus}
        options={this.getStatusOptions()}
        placeholder='Статус'
        value={this.getStatusValue()}
      />
    );
  }

  @bind
  renderModalActions(modal, edit) {
    if (edit) {
      return (
        <>
          {this.userType !== 'admin' && (
            <Button
              label='Удалить'
              onClick={() => modal.handleSubmit('delete')}
              variant='cancel'
            />
          )}
          <Button
            label='Сохранить'
            onClick={() => modal.handleSubmit('put')}
            variant='post'
          />
        </>
      );
    }

    return (
      <Button
        label='Создать'
        onClick={() => modal.handleSubmit('post')}
      />
    );
  }

  @bind
  renderOnlyAdminSearch() {
    if (this.userType === 'partner') return null;

    const { partnerId } = getQueryString();

    return (
      <Select
        className='partner-filter'
        onChange={this.filter('partnerId')}
        options={this.formatPartners()}
        placeholder='Партнёры'
        search
        value={partnerId}
      />
    );
  }

  render() {
    const { search } = getQueryString();
    const { modalOpen, editModalOpen } = this.state;
    const { count, stat } = this.props;

    return (
      <div className='page-container wrapper promocodes-groups-page'>
        <div className='header'>
          <h2 className='h2'>Группы промокодов</h2>
        </div>
        <div className='page-actions'>
          {this.userType === 'partner' && (
            <Button
              label='Создать группу'
              onClick={this.toggleModal}
            />
          )}
          {this.renderStatusFilter()}
          {this.renderOnlyAdminSearch()}
          <RangeDatePicker onChange={this.filterDate} />
          <Input
            label='Наименование'
            onChange={this.filterName}
            value={search}
          />
        </div>
        <div className='page-stats'>
          <MetaStat
            label='Количество групп промокодов'
            value={count}
          />
          <MetaStat
            label='Всего кодов'
            value={stat.total}
          />
          <MetaStat
            label='Всего продано кодов'
            value={stat.sold}
          />
        </div>
        <div className='table-container'>
          <Table
            options={this.getTableOptions()}
            rows={this.getTableRows()}
          />
        </div>
        <Pagination count={count} />
        <Modal
          className='promocodes-groups-modal'
          close={this.toggleModal}
          data={this.getModalData()}
          onClose={this.onCloseModal}
          open={modalOpen}
          renderActions={this.renderModalActions}
          renderContent={renderModalContent}
          title='Создать'
          uploadPhoto={this.props.uploadImage}
          userType={this.userType}
        />
        <Modal
          className='promocodes-groups-modal'
          close={this.toggleEditModal}
          data={this.getEditModalData()}
          onClose={this.onCloseModal}
          open={editModalOpen}
          renderActions={modal => this.renderModalActions(modal, true)}
          renderContent={renderModalContent}
          title={this.getEditModalTitle()}
          uploadPhoto={this.props.uploadImage}
          userType={this.userType}
        />
      </div>
    );
  }
}

const mapState = state => ({
  promocodesGroups: state.promocodes.items,
  count: state.promocodes.pagination.total,
  partners: state.partners.items,
  stat: state.promocodes.stat
});

const mapDispatch = {
  getPromocodesGroups,
  postPromocodesGroup,
  putPromocodesGroup,
  uploadImage,
  fetchPartners,
  deletePromocodeGroup,
  offPromocodeGroup
};

export default connect(
  mapState,
  mapDispatch
)(PromocodesPage);
