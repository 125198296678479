import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Select from '../components/Select';

import Table from '../components/table';
import Modal from '../modals/products-modal';
import Checkbox from '../components/checkbox';
import Pagination from '../components/oldPagination';

import { fetchProducts, addProduct, changeProduct } from '../actions/products';
import { uploadImage, fetchPartners } from '../actions/partners';

import { PRODUCTS_LIMIT } from '../lib/actions';

import withPageContainer from '../components/page';
import { getQueryString } from '../lib/functions';

class ProductsPage extends React.Component {
  componentDidMount() {
    this.props.getPartners({ limit: 9999 });
  }

  formatLimit(value) {
    return value ? '1 в день' : 'Без ограничений';
  }

  @bind
  formatData() {
    return this.props.products.map(({ ...product }, id) => ({
      ...product,
      active: <Checkbox value={product.active} />,
      dailyLimited: <Checkbox value={product.dailyLimited} />,
      image: <img
        alt='Изображение продута'
        src={product.image}
      />,
      info: (
        <button
          className='btn-reset btn-link'
          onClick={() => this.props.events.toggleEditModal(id)}
        >
          Инфо
        </button>
      )
    }));
  }

  @bind
  pushHistory(query) {
    const { history } = this.props;
    history.push(
      `${history.location.pathname}?${queryString.stringify({
        ...getQueryString(),
        ...query
      })}`
    );
  }

  @bind
  toggleSelect(id) {
    return () => {
      const openSelectId = this.state.openSelectId === id ? -1 : id;
      this.setState({ openSelectId });
    };
  }

  @bind
  handleSelect(name) {
    return value => {
      this.pushHistory({ [name]: value, page: 1 });
    };
  }

  @bind
  formatPartners() {
    return [
      { name: 'Все', value: '' },
      ...this.props.partners.map(partner => ({
        name: partner.shortName,
        value: partner.id
      }))
    ];
  }

  @bind
  getModalData() {
    const data = this.props.products[this.props.values.editModalId];
    if (!data) return { src: '' };
    return {
      ...data,
      partnerId: data.partner.id,
      src: ''
    };
  }

  render() {
    const { events, values } = this.props;
    const partnerId = getQueryString('partnerId');

    return (
      <React.Fragment>
        <div className='header'>
          <h2 className='h2'>Товары и услуги</h2>
        </div>
        <div className='page-actions'>
          <Select
            onChange={this.handleSelect('partnerId')}
            options={this.formatPartners()}
            placeholder='Партнёры'
            search
            value={partnerId}
          />
        </div>
        <div className='table-container'>
          <Table
            options={[
              { name: 'name', value: 'Товар' },
              {
                name: 'image',
                value: 'Изображение',
                className: 'table__image'
              },
              { name: 'cashback', value: 'Кэшбек' },
              { name: 'price', value: 'Цена' },
              { name: 'percentage', value: 'Процент скидки' },
              { name: 'discount', value: 'Скидка' },
              { name: 'dailyLimited', value: 'Лимит' },
              { name: 'active', value: 'Доступен' },
              { name: 'info', value: '', className: 'modal__info' }
            ]}
            rows={this.formatData()}
          />
        </div>
        <Modal
          action={{
            name: 'Сохранить',
            onClick: this.props.changeProduct,
            className: 'btn-secondary',
            defaultValues: {
              cashback: '0'
            }
          }}
          addPhoto={this.props.uploadImage}
          defaultState={this.getModalData()}
          edit
          onToggle={events.toggleEditModal}
          open={values.isOpenEditModal}
          title='Информация'
        />
        <Pagination
          limit={PRODUCTS_LIMIT}
          total={this.props.pagination.total}
          value={events.getQueryString('page')}
        />
      </React.Fragment>
    );
  }
}

const Page = withPageContainer(ProductsPage, { limit: PRODUCTS_LIMIT });

const mapStateToProps = state => ({
  products: state.products.items,
  isFetching: state.products.isFetching,
  pagination: state.products.pagination,
  partners: state.partners.items
});

const mapDispatchToProps = dispatch => ({
  uploadImage: (...args) => dispatch(uploadImage(...args)),
  fetch: (...args) => dispatch(fetchProducts(...args, 'admin')),
  addProduct: (...args) => dispatch(addProduct(...args)),
  changeProduct: (data, id) => dispatch(changeProduct(data, id, 'admin')),
  getPartners: query => dispatch(fetchPartners(query))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Page)
);
