import {
  URL,
  REQUEST_BONUSES,
  RECEIVE_BONUSES,
  BONUSES_LIMIT
} from '../lib/actions';

import { getQueryParametres } from '../lib/functions';

import { logout } from './user';

function requestBonuses() {
  return {
    type: REQUEST_BONUSES
  };
}

function receiveBonuses(response) {
  return {
    type: RECEIVE_BONUSES,
    response
  };
}

export function fetchBonuses(query = { page: 1, limit: BONUSES_LIMIT }) {
  return async (dispatch, getState) => {
    dispatch(requestBonuses());
    const request = await fetch(
      `${URL}/admin/bonuses?${getQueryParametres(query)}`,
      {
        method: 'GET',
        headers: {
          'x-access-token': getState().user.token
        }
      }
    );
    const { status } = await request;
    if (status === 200) {
      const response = await request.json();
      dispatch(receiveBonuses(response));
    } else {
      if (status === 401) {
        dispatch(logout());
      }
    }
  };
}

export function getFilters(query = {}) {
  return async (dispatch, getState) => {
    dispatch(requestBonuses());
    const request = await fetch(
      `${URL}/admin/bonuses/filters?${getQueryParametres(query)}`,
      {
        method: 'GET',
        headers: {
          'x-access-token': getState().user.token
        }
      }
    );
    const { status } = await request;
    if (status === 200) {
      const response = await request.json();
      dispatch({
        type: 'GET_FILTERS',
        response
      });
    } else {
      if (status === 401) {
        dispatch(logout());
      }
    }
  };
}
