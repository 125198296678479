import queryString from 'query-string';

import {
  URL,
  REQUEST_CLIENTS,
  RECEIVE_CLIENTS,
  CLIENTS_LIMIT,
  DELETE_CLIENT
} from '../lib/actions';

import { getQueryParametres } from '../lib/functions';

import { logout } from './user';

function requestClients() {
  return {
    type: REQUEST_CLIENTS
  };
}

function receiveClients(response) {
  return {
    type: RECEIVE_CLIENTS,
    response
  };
}

export function fetchClients(query = { page: 1, limit: 7 }) {
  return async (dispatch, getState) => {
    dispatch(requestClients());
    const request = await fetch(
      `${URL}/admin/clients?${getQueryParametres(query)}`,
      {
        method: 'GET',
        headers: {
          'x-access-token': getState().user.token
        }
      }
    );
    const { status } = await request;
    if (status === 200) {
      const response = await request.json();
      dispatch(receiveClients(response));
    } else {

      if (status === 401) {
        dispatch(logout());
      }
    }
  };
}

export function addClient(data) {
  return async (dispatch, getState) => {
    const request = await fetch(`${URL}/admin/clients`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getState().user.token
      },
      body: JSON.stringify(data)
    });
    const { status } = await request;
    if (status === 200) {
      dispatch(
        fetchClients({
          ...queryString.parse(window.location.search),
          limit: CLIENTS_LIMIT
        })
      );
    } else {
      switch (status) {
        case 400:
          alert('Заполните все поля');
          break;

        default:

          break;
      }
    }
    return status;
  };
}

export function changeClient(data, id) {
  return async (dispatch, getState) => {
    const request = await fetch(`${URL}/admin/clients/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getState().user.token
      },
      body: JSON.stringify(data)
    });
    const { status } = await request;
    if (status === 200) {
      dispatch(
        fetchClients({
          ...queryString.parse(window.location.search),
          limit: CLIENTS_LIMIT
        })
      );
    } else {
      switch (status) {
        case 400:
          alert('Заполните все поля');
          break;

        default:

          break;
      }
    }
    return status;
  };
}

export function addBonuses(data) {
  return async (dispatch, getState) => {
    const request = await fetch(`${URL}/admin/bonuses`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getState().user.token
      },
      body: JSON.stringify(data)
    });
    const { status } = await request;
    if (status === 200) {
      alert('Баланс изменен');
      dispatch(
        fetchClients({
          ...queryString.parse(window.location.search),
          limit: CLIENTS_LIMIT
        })
      );
    } else {
      switch (status) {
        default:

          break;
      }
    }
    return status;
  };
}

export function removeBonuses(data) {
  return async (dispatch, getState) => {
    const request = await fetch(`${URL}/admin/sales`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getState().user.token
      },
      body: JSON.stringify(data)
    });
    const { status } = await request;
    if (status === 200) {
      alert('Баланс изменен');
      dispatch(
        fetchClients({
          ...queryString.parse(window.location.search),
          limit: CLIENTS_LIMIT
        })
      );
    } else {
      switch (status) {
        default:

          break;
      }
    }
    return status;
  };
}

export const deleteClient = id => async (dispatch, getState) => {
  const request = await fetch(`${URL}/admin/clients/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': getState().user.token
    }
  });

  const { status } = await request;

  if (status === 200) {
    dispatch({
      type: DELETE_CLIENT,
      id
    });
    dispatch(fetchClients(queryString.parse(window.location.search)));
  }
};
