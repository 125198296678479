import request from '../lib/request';

export const GET_PARTNER_TYPES = 'GET_PARTNER_TYPES';

export const getPartnerTypes = () => async dispatch => {
  const success = response => {
    dispatch({
      type: GET_PARTNER_TYPES,
      response
    });
  };

  await request('/info/partner_types', { success });
};

export const createPartnerType = (body, success) => async dispatch => {
  const failed = (response, status) => {
    switch (status) {
      case 500:
        alert('Ошибка сервера');
        break;

      default:
        if (response.message) {
          alert(response.message);
        } else {
          alert('Произошла ошибка');
        }
        break;
    }
  };

  await request('/info/partner_types', {
    method: 'POST',
    body: JSON.stringify(body),
    success,
    failed
  });
};

export const deletePartnerType = (id, success) => async dispatch => {
  const failed = response => {
    alert(response.message);
  };

  await request(`/info/partner_types/${id}`, {
    method: 'DELETE',
    success,
    failed
  });
};
