import { URL, REQUEST_CITIES, RECEIVE_CITIES } from '../lib/actions';

import { getQueryParametres } from '../lib/functions';

import { logout } from './user';

function requestCities() {
  return {
    type: REQUEST_CITIES
  };
}

function receiveCities(response) {
  return {
    type: RECEIVE_CITIES,
    response
  };
}

export function fetchCities(query = { page: 1, limit: 7 }) {
  return async (dispatch, getState) => {
    dispatch(requestCities());
    const request = await fetch(
      `${URL}/info/cities?${getQueryParametres(query)}`,
      {
        method: 'GET',
        headers: {
          'x-access-token': getState().user.token
        }
      }
    );
    const { status } = await request;
    if (status === 200) {
      const response = await request.json();
      dispatch(receiveCities(response));
    } else {

      if (status === 401) {
        dispatch(logout());
      }
    }
  };
}
