import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { shallowEqualObjects } from 'shallow-equal';
import queryString from 'query-string';

import { deletePartnerType, getPartnerTypes } from '../actions/partnerTypes';

import {
  deleteSubject,
  getSettings,
  setCashBack,
  updateSettings,
  updateSubjectSettings,
  getTransactionLimit,
  setTransactionLimit,
  setBonusDateDnevnik,
  setBonusDateEljur
} from '../actions/settings';

import Button from '../components/Button';
import Datepicker from '../components/RangeDatePicker';
import Input from '../components/Input';
import Select from '../components/Select';
import Checkbox from '../components/checkbox';
import TableActions from '../components/TableActions';
import { withConfirm, getQueryString } from '../lib/functions';
import PartnerTypesModal from '../modals/partnerTypesModal';
import SubjectModal from '../modals/subjectsModal';
import partnerTypes from '../reducers/partnerTypes';

class PartnersPage extends Component {
  state = {
    cashback: 0,
    referralBonus: 0,
    registerBonus: 0,
    transactionLimit: 0,
    type: '',
    subject: '',
    updateTransactions: false,
    subjectStatusDone: false,
    androidVersion: '',
    iosVersion: '',

    bonusDateFrom: '',
    bonusDateTo: '',

    subjectModalOpen: false,
    editableSubject: '',

    partnerTypesModalOpen: false
  };

  componentDidMount() {
    if (this.getUserType() === 'admin') {
      this.props.getSettings();
      this.props.getTransactionLimit();
    }

    this.initState();
  }

  componentDidUpdate(prevProps) {
    if (!shallowEqualObjects(prevProps.settings, this.props.settings)) {
      this.setState(this.props.settings);
    }
  }

  initState = () => this.setState(this.props.settings);

  @bind
  handleChange(name) {
    return e => {
      this.setState({ [name]: e.target.value });
    };
  }

  @bind
  handleSelect(name) {
    return value => {
      this.setState({
        [name]: value,
        subjectStatusDone: false
      });
    };
  }

  @bind
  getUserType() {
    return this.props.location.pathname.includes('admin') ? 'admin' : 'partner';
  }

  @bind
  changeCashback(e) {
    let { value } = e.target;
    if (Number(value) > 99) {
      value = 99;
    }
    if (Number(value) < 0) {
      value = 0;
    }
    this.setState({ cashback: value });
  }

  @bind
  handleSubmit() {
    const {
      cashback,
      referralBonus,
      registerBonus,
      androidVersion,
      iosVersion,
      transactionLimit
    } = this.state;

    this.props.updateSettings({
      referralBonus: Number(referralBonus),
      registerBonus: Number(registerBonus),
      androidVersion,
      iosVersion
    });
    this.props.setCashBack(Number(cashback));
    this.props.setTransactionLimit(Number(transactionLimit));
  }

  @bind
  onUpdateSubjectSettings() {
    const callback = async (response, status) => {
      if (status === 200) {
        await this.props.getSettings();
        this.setState({
          subjectStatusDone: true,
          loading: false
        });
      } else {
        alert(response.message);
        this.setState({ loading: false });
      }
    };

    const { type, subject, updateTransactions } = this.state;

    this.setState({ loading: true });
    this.props.updateSubjectSettings(
      {
        type,
        subject,
        updateTransactions
      },
      callback
    );
  }

  @bind
  onCheck() {
    this.setState({ updateTransactions: !this.state.updateTransactions });
  }

  @bind
  onOpenSubjectModal(subject) {
    if (typeof subject !== 'string') {
      // eslint-disable-next-line no-param-reassign
      subject = '';
    }
    this.setState({
      subjectModalOpen: true,
      editableSubject: subject
    });
  }

  @bind
  onCloseSubjectModal() {
    this.setState({ subjectModalOpen: false });
  }

  @bind
  onClosePartnerTypesModal() {
    this.setState({ partnerTypesModalOpen: !this.state.partnerTypesModalOpen });
  }

  @bind
  getTypes() {
    return this.props.settings.types.map(type => ({
      name: `${type.type} (${type.subject})`,
      value: type.type
    }));
  }

  @bind
  handleChangeDate(from, to) {
    this.state.bonusDateFrom = from;
    this.state.bonusDateTo = to;
  }

  @bind
  handleSubmitBonusDateEljur() {
    const { bonusDateFrom, bonusDateTo } = this.state;

    this.props.setBonusDateEljur(bonusDateFrom, bonusDateTo);
  }

  @bind
  handleSubmitBonusDateDnevnik() {
    const { bonusDateFrom, bonusDateTo } = this.state;

    this.props.setBonusDateDnevnik(bonusDateFrom, bonusDateTo);
  }

  @bind
  getSubjects() {
    return this.props.settings.subjects.map(subject => ({
      name: (
        <div className='select-option-subject'>
          <span>{subject}</span>
          <TableActions
            items={[
              {
                icon: 'edit',
                onClick: () => this.onOpenSubjectModal(subject)
              },
              {
                icon: 'delete',
                onClick: withConfirm(
                  () => this.props.deleteSubject(subject),
                  'Удалить категорию?'
                )
              }
            ]}
          />
        </div>
      ),
      value: subject
    }));
  }

  @bind
  getPartnerTypes() {
    return this.props.partnerTypes.list.map(type => ({
      name: (
        <div
          className='select-option-subject'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <span>{type.name}</span>
          <TableActions
            items={[
              {
                icon: 'delete',
                onClick: withConfirm(
                  () =>
                    this.props.deletePartnerType(type.id, () => {
                      this.props.getPartnerTypes();
                    }),
                  'Удалить тип?'
                )
              }
            ]}
          />
        </div>
      ),
      value: type.id
    }));
  }

  @bind
  formatEmptyTypes() {
    return this.props.settings.incorrectSubjects.map(type => ({
      name: `${type.type} (${type.subject})`,
      value: type.type
    }));
  }

  @bind
  renderEmptyTypes() {
    return (
      <div className='empty-types-list'>
        <Select
          alwaysOpen
          onChange={this.handleSelect('type')}
          options={this.formatEmptyTypes()}
          placeholder='Поиск'
          search
        />
      </div>
    );
  }

  render() {
    const {
      cashback,
      referralBonus,
      registerBonus,
      updateTransactions,
      type,
      subject,
      subjectStatusDone,
      androidVersion,
      iosVersion,
      transactionLimit,
      loading
    } = this.state;

    if (this.getUserType() === 'partner') {
      const links = [
        {
          href: 'http://doc.globus.best',
          label: 'Ссылка на документацию'
        },
        {
          href:
            'https://play.google.com/store/apps/details?id=ru.vvdev.globuskassir',
          label: 'Globus Operator (Google Play)'
        },
        {
          href: 'https://apps.apple.com/ru/app/globus-operator/id1459629667',
          label: 'Globus Operator (App Store)'
        },
        {
          href: 'https://play.google.com/store/apps/details?id=ru.vvdev.globus',
          label: 'Globus Best (Google Play)'
        },
        {
          href: 'https://apps.apple.com/ru/app/globus-best/id1459642803',
          label: 'Globus Best (App Store)'
        }
      ];

      return (
        <div className='page-container wrapper settings-page'>
          <h2 className='h2'>Настройки</h2>
          <div className='page-block'>
            {links.map(({ href, label }) => (
              <a
                className='link'
                href={href}
                key={href}
                target='_blank'
              >
                {label}
              </a>
            ))}
          </div>
        </div>
      );
    }

    return (
      <div className='page-container wrapper settings-page'>
        <div className='page-row'>
          <div className='page-block types'>
            <h2 className='h2'>Соответствие Предмет - Категория</h2>

            <Select
              onChange={this.handleSelect('type')}
              options={this.getTypes()}
              placeholder='Предметы'
              search
              value={type}
            />
            <div style={{ display: 'flex' }}>
              <Button
                label='+'
                onClick={this.onOpenSubjectModal}
              />
              <Select
                onChange={this.handleSelect('subject')}
                options={this.getSubjects()}
                placeholder='Категории'
                style={{
                  marginLeft: '20px',
                  width: '100%'
                }}
                value={subject}
              />
            </div>
            <Checkbox
              checked={updateTransactions}
              label='Обновить старые транзакции'
              onChange={this.onCheck}
            />
            <Button
              label='Привязать'
              loading={loading}
              onClick={this.onUpdateSubjectSettings}
            />
            {subjectStatusDone && (
              <div className='subject-status'>
                Соответствие {type} - {subject} установлено
              </div>
            )}
          </div>
          <div className='page-block empty-types'>
            <h2 className='h2'>
              Список предметов, для которых настройка незаполнена
            </h2>

            {this.renderEmptyTypes()}
          </div>
        </div>
        <div className='page-row'>
          <div className='page-block'>
            <h2 className='h2'>Настройки</h2>

            <Input
              label='Кэшбек'
              onChange={this.changeCashback}
              value={cashback}
            />
            <Input
              label='Бонусы реферальной программы'
              onChange={this.handleChange('referralBonus')}
              value={referralBonus}
            />
            <Input
              label='Бонусы за регистрацию'
              onChange={this.handleChange('registerBonus')}
              value={registerBonus}
            />
            <Button
              label='Сохранить'
              onClick={this.handleSubmit}
            />
            <Input
              label='Лимит транзакций доброрублей'
              onChange={this.handleChange('transactionLimit')}
              value={transactionLimit}
            />
            <Button
              label='Сохранить'
              onClick={this.handleSubmit}
            />
          </div>
          <div className='page-block'>
            <h2 className='h2'>Версии</h2>
            <Input
              label='Андроид'
              onChange={this.handleChange('androidVersion')}
              value={androidVersion}
            />
            <Input
              label='Айфон'
              onChange={this.handleChange('iosVersion')}
              value={iosVersion}
            />
            <Button
              label='Сохранить'
              onClick={this.handleSubmit}
            />
          </div>
        </div>
        <div className='page-row'>
          <div className='page-block partner-types'>
            <h2 className='h2'>Типы партнеров</h2>
            <div style={{ display: 'flex' }}>
              <Button
                label='+'
                onClick={this.onClosePartnerTypesModal}
              />
              <Select
                alwaysOpen
                onChange={() => {}}
                options={this.getPartnerTypes()}
                placeholder='Типы'
                style={{
                  marginLeft: '20px',
                  width: '100%'
                }}
              />
            </div>
          </div>
        </div>
        <div className='page-row'>
          <div className='page-block partner-types'>
            <h2 className='h2'>Рейтинг по Нижегородской области</h2>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
                width: 'max-content'
              }}
            >
              <Datepicker onChange={this.handleChangeDate} />
              <Button
                label='Экспорт из Элжур-НН'
                onClick={this.handleSubmitBonusDateEljur}
              />
              <Button
                label='Экспорт из Дневник-Ру'
                onClick={this.handleSubmitBonusDateDnevnik}
              />
            </div>
          </div>
        </div>

        <SubjectModal
          editableSubject={this.state.editableSubject}
          isOpen={this.state.subjectModalOpen}
          onClose={this.onCloseSubjectModal}
        />
        <PartnerTypesModal
          isOpen={this.state.partnerTypesModalOpen}
          onClose={this.onClosePartnerTypesModal}
        />
      </div>
    );
  }
}

const mapState = state => ({
  settings: state.settings,
  partnerTypes: state.partnerTypes
});

const mapDispatchToProps = {
  updateSettings,
  updateSubjectSettings,
  setCashBack,
  getSettings,
  setBonusDateDnevnik,
  setBonusDateEljur,
  deleteSubject,
  deletePartnerType,
  getPartnerTypes,
  getTransactionLimit,
  setTransactionLimit
};

export default withRouter(connect(mapState, mapDispatchToProps)(PartnersPage));
