import { REQUEST_CITIES, RECEIVE_CITIES } from '../lib/actions';
import { CITIES, CITIES_PAGINATION } from '../lib/storageVariables';
import { getLocalStorage, setLocalStorage } from '../lib/functions';

const STATE = {
  isFetching: false,
  items: getLocalStorage(CITIES) || [],
  pagination: getLocalStorage(CITIES_PAGINATION) || []
};

export default function(state = STATE, action) {
  switch (action.type) {
    case REQUEST_CITIES:
      return {
        ...state,
        isFetching: true
      };

    case RECEIVE_CITIES:
      const cities = action.response.data;

      if (cities) setLocalStorage(CITIES, cities);

      return {
        ...state,
        isFetching: false,
        items: cities || state.items
      };

    default:
      return state;
  }
}
