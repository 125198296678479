import queryString from 'query-string';
import moment from 'moment';
import 'moment/locale/ru';

export const getLocalStorage = name => {
  let response = 'Name is not found';
  if (name) {
    if (
      localStorage.getItem(name) &&
      localStorage.getItem(name) !== 'undefined' &&
      localStorage.getItem(name) !== 'false'
    ) {
      const storage = localStorage.getItem(name);
      const result = JSON.parse(storage);

      if (result && result.timestamp) {
        const currentTime = new Date().getTime();
        const expireTime = 200000;

        if (currentTime - result.timestamp > expireTime) {
          response = false;
          localStorage.setItem(name, JSON.stringify(false));
        } else response = result;
      } else response = result;
    } else response = false;
  }

  return response;
};

export const setLocalStorage = (name, item) => {
  localStorage.setItem(name, JSON.stringify(item));
};

export const truncate = (str, maxlength) => {
  if (typeof str !== 'string') return str;
  if (str.length > maxlength) {
    return `${str.slice(0, maxlength - 3)}...`;
  }

  return str;
};

export const getValue = (key, value) => {
  if (Array.isArray(value))
    return value.map(val => getValue(key, val)).join('&');
  return `${key}=${encodeURIComponent(value)}`;
};

export const getQueryParametres = (obj = {}) => {
  let res = '';

  const keys = Object.keys(obj);
  for (let j = 0; j < keys.length; j += 1) {
    const i = keys[j];
    if (obj[i] !== undefined) {
      if (res === '') {
        res = getValue(i, obj[i]);
      } else {
        res += `&${getValue(i, obj[i])}`;
      }
    }
  }
  return res;
};

export const fileReader = file =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = event => resolve(event.target.result);
  });

export const withConfirm = (fn, mes = 'Закрыть без сохранения?') => (
  ...args
) => {
  if (!window.confirm(mes)) return;
  fn(...args);
};

export const getQueryString = (param, search) => {
  const params = queryString.parse(search || window.location.search);

  for (const key in params) {
    if (!params[key]) delete params[key]; // выпилить если пустой
  }

  return param ? params[param] : params;
};

export function formatPhone(str) {
  if (typeof str !== 'string') return str;
  return str.replace(/\+7(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($1) $2-$3-$4');
}

export function formatPhoneForQuery(phone) {
  return phone.replace(/[-()_\s]/g, '');
}

export function formatDateToOutput(date) {
  return moment(date).format('YYYY MMM DD');
}

export function pushHistory(query) {
  const { history } = this.props;

  const formatedQuery = {
    ...getQueryString(),
    ...query
  };

  for (const key in formatedQuery) {
    if (!formatedQuery[key]) {
      delete formatedQuery[key];
    }
  }

  history.push(
    `${history.location.pathname}?${queryString.stringify({
      ...formatedQuery
    })}`
  );
}

export function modalBind(prop, nestedProp) {
  return {
    value: nestedProp
      ? this.state.data[nestedProp][prop]
      : this.state.data[prop],
    onChange: this.handleChange(prop, nestedProp)
  };
}

export function shouldFetch(prevProps) {
  return this.props.location.search !== prevProps.location.search;
}

export function parseInteger(value) {
  let val = parseInt(value, 10);
  if (!val && Number.isNaN(val)) val = '0';
  return val;
}

export function getRandom() {
  return String(Math.random()).split('.')[1];
}

export const getUserType = () =>
  window.location.pathname.includes('admin') ? 'admin' : 'partner';
