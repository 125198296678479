import {
  LOGIN, LOGOUT, GET_PARTNER_INFO, CLEAR_PARTNER_INFO,
} from '../lib/actions';
import { USER_TOKEN, PARTNER_INFO } from '../lib/storageVariables';
import { setLocalStorage, getLocalStorage } from '../lib/functions';

const STATE = {
  token: getLocalStorage(USER_TOKEN) || '',
  partnerInfo: null
};

export default function(state = STATE, action) {
  switch (action.type) {
    case LOGIN:
      setLocalStorage(USER_TOKEN, action.response.data.token);
      return {
        ...state,
        token: action.response.data.token || state.token
      };
    case LOGOUT:
      setLocalStorage(USER_TOKEN, '');
      return {
        ...state,
        token: ''
      };
    case GET_PARTNER_INFO:
      return {
        ...state,
        partnerInfo: action.response.data
      };

    case CLEAR_PARTNER_INFO:
      return {
        ...state,
        partnerInfo: null
      };

    default:
      return state;
  }
}
