import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import './style.scss';
import { fileReader, withConfirm } from '../../lib/functions';

export function withModalContainer(Content) {
  return class extends React.Component {
    static defaultProps = {
      defaultState: {}
    };

    state = this.props.defaultState;

    componentWillUpdate(nextProps) {
      if (
        JSON.stringify(nextProps.defaultState) !==
        JSON.stringify(this.props.defaultState)
      ) {
        // через JSON т.к. с бека всегда приходит объект с одинаковым порядком свойств
        this.setState(nextProps.defaultState);
      }
    }

    componentDidUpdate(prevProps) {
      // set default state at closing
      if (!prevProps.open && this.props.open)
        this.setState(this.props.defaultState);
    }

    @bind
    handleLoadFile(filename, userType) {
      return () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/png,image/jpeg';

        input.addEventListener('change', async event => {
          const file = Array.from(event.currentTarget.files)[0];
          const src = await fileReader(file);
          const response = await this.props.addPhoto(file, userType);
          let photo = '';

          if (response) {
            photo = response.data.filename;
          }

          if (photo) {
            this.setState({
              src,
              [filename]: photo
            });
          }
        });

        input.click();
      };
    }

    @bind
    handleCheck(name) {
      return () => {
        this.setState({
          [name]: !this.state[name]
        });
      };
    }

    @bind
    handleSelect(name) {
      return value => this.setState({ [name]: value });
    }

    @bind
    handleToggleSelect() {
      this.setState({ isSelectOpen: !this.state.isSelectOpen });
    }

    @bind
    handleSelectSuggest(data) {
      this.setState(data);
    }

    @bind
    defaultChangeHandler(nextState = {}) {
      this.setState(nextState);
    }

    @bind
    handleChange(name, obj = '') {
      return event => {
        const value =
          event.target.type === 'number'
            ? Number(event.target.value)
            : event.target.value;

        if (obj) {
          // if nested prop
          this.setState({
            [obj]: {
              ...this.state[obj],
              [name]: value
            }
          });
        } else {
          this.setState({
            [name]: value
          });
        }
      };
    }

    @bind
    completeData() {
      const { defaultValues } = this.props.action;
      const data = { ...this.state };
      if (!defaultValues) return data;
      for (const value in defaultValues) {
        data[value] = data[value] || defaultValues[value];
      }
      return data;
    }

    @bind
    async handleSubmit(event) {
      event.preventDefault();

      const data = this.completeData();
      delete data.src;

      const status = await this.props.action.onClick(data, data.id);
      if (status === 200) this.props.onToggle();
    }

    events = {
      handleSelect: this.handleSelect,
      handleCheck: this.handleCheck,
      handleChange: this.handleChange,
      handleLoadFile: this.handleLoadFile,
      handleToggleSelect: this.handleToggleSelect,
      handleSelectSuggest: this.handleSelectSuggest,
      defaultChangeHandler: this.defaultChangeHandler
    };

    closeWithConfirm = withConfirm(this.props.onToggle);

    render() {
      return (
        <Dialog
          classes={{
            paper: 'modal'
          }}
          onClose={this.closeWithConfirm}
          open={this.props.open}
        >
          <DialogTitle
            classes={{
              root: 'modal__title'
            }}
          >
            {this.props.title}
            <IconButton
              className='modal__close-btn'
              onClick={this.closeWithConfirm}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            classes={{
              root: 'modal__content'
            }}
          >
            <form id='modal'>
              <Content
                {...this.props}
                events={this.events}
                values={this.state}
              />
            </form>
          </DialogContent>
          {this.props.action && (
            <DialogActions
              classes={{
                root: 'modal__footer'
              }}
            >
              <button
                className={`modal__btn${
                  this.props.action.className
                    ? ` ${this.props.action.className}`
                    : ''
                }`}
                form='modal'
                onClick={this.handleSubmit}
                type='button'
              >
                {this.props.action.name}
              </button>
            </DialogActions>
          )}
        </Dialog>
      );
    }
  };
}
