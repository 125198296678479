import React from 'react';
import { connect } from 'react-redux';

import { withRouter, NavLink, Link } from 'react-router-dom';
import SVG from 'react-svg';

import { logout } from '../../actions/user';

import logo from '../../image/logo.svg';
import burger from '../../image/burger.svg';

import './style.scss';
import { getUserType, truncate } from '../../lib/functions';

class LeftMenu extends React.Component {
  static defaultProps = {
    userInfo: {}
  };

  state = {
    isOpen: false
  };

  userType = getUserType();

  @bind
  handleLogout() {
    this.props.logout();
  }

  @bind
  handleClick() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  @bind
  renderLinks() {
    let list = [
      {
        name: 'Партнеры',
        url: '/admin/partners'
      },
      {
        name: 'Товары',
        url: '/admin/products'
      },
      {
        name: 'Промокоды',
        url: '/admin/promocodes'
      },
      {
        name: 'Филиалы',
        url: '/admin/places'
      },
      {
        name: 'Транзакции',
        url: '/admin'
      },
      {
        name: 'Пользователи',
        url: '/admin/users'
      },
      {
        name: 'Бонусы',
        url: '/admin/bonuses'
      },
      {
        name: 'Настройки',
        url: '/admin/settings'
      }
    ];

    if (this.userType === 'partner') {
      const { partnerInfo } = this.props;

      list = [
        {
          name: 'Филиалы',
          url: '/branches'
        },
        {
          name: 'Сотрудники',
          url: '/employees'
        },
        {
          name: 'Товары',
          url: '/products'
        },
        {
          name: 'Транзакции',
          url: '/'
        },
        {
          name: 'Настройки',
          url: '/settings'
        }
      ];

      if (partnerInfo && partnerInfo.promocodesAllowed) {
        list.splice(
          3,
          0,
          {
            name: 'Промокоды',
            url: '/promocodes'
          }
        )
      }
    }

    const renderLink = link => (
      <NavLink
        activeClassName='menu-link-active'
        className='menu-link'
        exact
        onClick={this.handleClick}
        to={link.url}
      >
        {link.name}
      </NavLink>
    );

    return (
      <ul className='menu-list'>
        {list.map(link => {
          if (link.list) {
            return (
              <li key={link.url}>
                <span className='list-title'>{renderLink(link)}</span>
                <ul className='submenu-list'>
                  {link.list.map(l => (
                    <li key={l.url}>{renderLink(l)}</li>
                  ))}
                </ul>
              </li>
            );
          }

          return <li key={link.url}>{renderLink(link)}</li>;
        })}
      </ul>
    );
  }

  @bind
  renderPartnerInfo() {
    if (!this.props.partnerInfo) return null;
    const { shortName, fullName } = this.props.partnerInfo;
    const descr = truncate(fullName, 100);

    return (
      <div className='nav-bar__user-info'>
        <h4 className='user-info__name'>
          {shortName}
        </h4>
        <p className='user-info__descr'>{descr}</p>
      </div>
    )
  }

  render() {
    return (
      <>
        <div className='flex-bar' />
        <nav className='nav-bar'>
          <div className='nav-bar__app-bar'>
            <Link
              className='nav-bar__logo'
              to={`/${this.props.location.pathname}`}
            >
              <SVG src={logo} />
            </Link>
            <div
              className='nav-bar__burger'
              onClick={this.handleClick}
            >
              <SVG src={burger} />
            </div>
          </div>
          <div
            className={`nav-bar__link-container${
                this.state.isOpen ? ' nav-bar__link-container_open' : ''
                }`}
          >
            <div>
              {this.renderPartnerInfo()}
              {this.renderLinks()}
            </div>
            <button
              className='nav-bar__link nav-bar__button btn-reset'
              onClick={this.handleLogout}
            >
              Выйти
            </button>
            </div>
        </nav>
      </>
    );
  }
}

const mapState = state => ({
  partnerInfo: state.user.partnerInfo
});

const mapDispatch = {
  logout
};

export default withRouter(
  connect(
    mapState,
    mapDispatch
  )(LeftMenu)
);
