import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { createSubject, updateSubject } from '../actions/settings';
import Button from '../components/Button';

import Modal from '../components/Modal';
import Input from '../components/Input';

const SubjectModal = ({ isOpen, onClose, editableSubject = '' }) => {
  const dispatch = useDispatch();

  const [subject, setSubject] = useState('');

  useEffect(() => {
    if (isOpen && editableSubject) {
      setSubject(editableSubject);
    } else {
      setSubject('');
    }
  }, [isOpen]);

  const handleSubmit = () => {
    const callback = () => onClose();

    if (editableSubject) {
      dispatch(updateSubject(editableSubject, subject, callback));
    } else {
      dispatch(createSubject(subject, callback));
    }
  };

  return (
    <Modal
      close={onClose}
      open={isOpen}
      title={editableSubject ? 'Изменение категории' : 'Создание категории'}
    >
      <Input
        label='Название категории'
        onChange={e => setSubject(e.target.value)}
        value={subject}
      />
      <Button
        label='Сохранить'
        onClick={handleSubmit}
      />
    </Modal>
  );
};

export default SubjectModal;
