import { GET_PARTNER_TYPES } from '../actions/partnerTypes';

const STATE = {
  list: []
};

export default function(state = STATE, action) {
  switch (action.type) {
    case GET_PARTNER_TYPES:
      return {
        ...state,
        list: action.response
      };

    default:
      return state;
  }
}
