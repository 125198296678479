import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';

import { getUpload } from '../actions/export';

import {
  deletePromocodes,
  generatePromocode,
  getPromocodesGroupById
} from '../actions/promocodes';

import Button from '../components/Button';
import Table from '../components/table';
import Modal from '../components/Modal';
import Pagination from '../components/Pagination';
import TableActions from '../components/TableActions';
import { getQueryString, shouldFetch } from '../lib/functions';
import renderModalContent from '../modals/promocodeGenerateModal';
import arrow from '../image/select.svg';

class PromocodePage extends Component {
  state = { modalOpen: false, generate: false };

  componentDidMount() {
    this.fetchWithQuery();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.shouldFetch(prevProps)) this.fetchWithQuery();
  }

  shouldFetch = shouldFetch.bind(this);

  userType = this._getUserType();

  id = this.props.match.params.id;

  @bind
  _getUserType() {
    return this.props.location.pathname.includes('admin') ? 'admin' : 'partner';
  }

  fetchWithQuery() {
    if (this.id)
      this.props.getPromocodesGroupById(
        this.id,
        getQueryString(),
        this.userType
      );
  }

  getTableOptions = () => [
    {
      name: 'id',
      value: 'Идентификатор'
    },
    {
      name: 'description',
      value: 'Описание'
    },
    {
      name: 'sale',
      value: 'Скидка'
    },
    {
      name: 'status',
      value: 'Статус'
    }
  ];

  getPromocodeStatus = status => {
    switch (status) {
      case 'new':
        return 'Новый';
      case 'sold':
        return 'Продан';
      default:
        return status;
    }
  };

  getTableRows = () =>
    this.props.promocodesGroup.data.map(promocode => ({
      ...promocode,
      status: this.getPromocodeStatus(promocode.status),
      sale: `${promocode.sale}%`
    }));

  @bind
  toggleModal() {
    this.setState({ modalOpen: !this.state.modalOpen });
  }

  @bind
  toggleEditModal(id) {
    this.setState({ editModalOpen: !this.state.editModalOpen, dataId: id });
  }

  @bind
  onCloseModal(data) {
    const callback = (response, status) => {
      if (status === 200) {
        this.setState({ generate: false, modalOpen: false });
      }
      this.setState({ generate: false });
    };

    this.setState({ generate: true });
    this.props.generatePromocode(data, this.id, 'partner', callback);

    return false;
  }

  @bind
  getUpload() {
    const path =
      this.userType === 'admin' ? 'admin/promocodes' : 'partner/promocodes';

    this.props.getUpload(path, { groupId: this.id });
  }

  getModalData = () => ({
    data: {
      length: 6,
      letters: false,
      digits: false,
      quantity: 0
    }
  });

  @bind
  uploadPromocodes() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '';

    input.addEventListener('change', event => {
      const file = Array.from(event.currentTarget.files)[0];
      this.props.generatePromocode(file, this.id, this.userType);
    });

    input.click();
  }

  @bind
  async loadDemoFile() {
    const request = await fetch('/demoFile.xlsx', {
      method: 'GET'
    });
    const file = await request.blob();
    saveAs(file, 'demo_file.xlsx');
  }

  @bind
  getPageTitle() {
    const { promocodesGroup } = this.props;
    const promocode = promocodesGroup.data[0];
    if (promocode) return promocode.name;
    return 'Промокод';
  }

  @bind
  getBackLink() {
    if (this.userType === 'admin') return '/admin/promocodes';
    return '/promocodes';
  }

  @bind
  deletePromocodes() {
    if (window.confirm('Точно хотите удалить?')) {
      this.props.deletePromocodes(this.id, () => this.fetchWithQuery());
    }
  }

  @bind
  renderModalActions(modal) {
    if (this.state.generate) {
      return <Button
        disabled
        label='Идет генерация'
      />;
    }

    return <Button
      label='Сгенерировать'
      onClick={modal.handleSubmit}
    />;
  }

  @bind
  renderPageActions() {
    if (this.userType === 'admin') return null;

    const { promocodesGroup } = this.props;

    if (promocodesGroup.data.length)
      return (
        <div className='page-actions'>
          <div>
            <Button
              label='Удалить промокоды'
              onClick={this.deletePromocodes}
            />
          </div>
        </div>
      );

    if (getQueryString('off') === 'true') return null;

    return (
      <div className='page-actions'>
        <div>
          <Button
            label='Загрузить'
            onClick={this.uploadPromocodes}
          />
          <Button
            label='Сгенерировать'
            onClick={this.toggleModal}
            variant='border'
          />
        </div>
        <div>
          <Button
            label='Пример файла'
            onClick={this.loadDemoFile}
          />
        </div>
      </div>
    );
  }

  render() {
    const { modalOpen } = this.state;
    const { total } = this.props.promocodesGroup.pagination;

    return (
      <div className='page-container wrapper promocode-group-page'>
        <div className='page-header'>
          <div
            style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
          >
            <h2
              className='h2'
              style={{ marginRight: '20px' }}
            >
              {this.getPageTitle()}
            </h2>
            <Button
              label='Экспорт'
              onClick={this.getUpload}
              variant='neutral'
            />
          </div>
          <div className='back'>
            <ReactSVG
              className='back-svg'
              src={arrow}
            />
            <Link to={this.getBackLink()}>К списку групп</Link>
          </div>
        </div>
        {this.renderPageActions()}
        <div className='table-container'>
          <Table
            options={this.getTableOptions()}
            rows={this.getTableRows()}
          />
        </div>
        <Pagination count={total} />
        <Modal
          className='promocode-group-modal'
          close={this.toggleModal}
          data={this.getModalData()}
          onClose={this.onCloseModal}
          open={modalOpen}
          renderActions={this.renderModalActions}
          renderContent={renderModalContent}
          title='Сгенерировать'
        />
      </div>
    );
  }
}

const mapState = state => ({
  promocodesGroup: state.promocodes.itemById
});

const mapDispatch = {
  getPromocodesGroupById,
  generatePromocode,
  deletePromocodes,
  getUpload
};

export default connect(
  mapState,
  mapDispatch
)(PromocodePage);
