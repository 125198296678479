import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button from '../components/Button';

import SearchButton from '../components/SearchButton';

import Select from '../components/Select';

import Table from '../components/table';
import Modal from '../modals/products-modal';
import Checkbox from '../components/checkbox';
import Pagination from '../components/oldPagination';

import {
  fetchProducts,
  addProduct,
  changeProduct,
  deleteProduct
} from '../actions/products';
import { uploadImage } from '../actions/partners';

import { PRODUCTS_LIMIT } from '../lib/actions';

import withPageContainer from '../components/page';
import { pushHistory, getQueryString, withConfirm } from '../lib/functions';

class ProductsPage extends React.Component {
  pushHistory = pushHistory.bind(this);

  state = {
    name: ''
  };

  @bind
  handleSelect(name) {
    return value => {
      this.pushHistory({
        [name]: value,
        page: 1
      });
    };
  }

  @bind
  handleChangeName(event) {
    this.setState({ name: event.target.value });
  }

  @bind
  handleSearchByName() {
    this.pushHistory({
      name: this.state.name,
      page: 1
    });
  }

  @bind
  handleChangeDate(from, to) {
    this.pushHistory({
      from,
      to
    });
  }

  @bind
  formatData() {
    return this.props.products.map(({ ...product }, id) => ({
      ...product,
      active: <Checkbox value={product.active} />,
      dailyLimited: <Checkbox value={product.dailyLimited} />,
      image: <img
        alt='Изображение продута'
        src={product.image}
      />,
      info: (
        <button
          className='btn-reset btn-link'
          onClick={() => this.props.events.toggleEditModal(id)}
        >
          Инфо
        </button>
      ),
      remove: (
        <button
          className='btn-reset btn-link'
          onClick={() =>
            withConfirm(this.props.deleteProduct, 'Удалить товар?')(product.id)
          }
        >
          Удалить
        </button>
      )
    }));
  }

  render() {
    const { events, values } = this.props;
    const data = this.props.products[values.editModalId] || {};
    const { dailyLimited, active, sort } = getQueryString();

    return (
      <React.Fragment>
        <div className='header'>
          <h2 className='h2'>Товары и услуги</h2>
        </div>
        <div className='page-actions'>
          <Select
            onChange={this.handleSelect('dailyLimited')}
            options={[
              {
                name: 'Все',
                value: ''
              },
              {
                name: 'С ежедневным лимитом',
                value: 'true'
              },
              {
                name: 'Без лимита',
                value: 'false'
              }
            ]}
            placeholder='Лимит'
            style={{ maxWidth: '300px' }}
            value={dailyLimited}
          />
          <Select
            onChange={this.handleSelect('active')}
            options={[
              {
                name: 'Все',
                value: ''
              },
              {
                name: 'Активные',
                value: 'true'
              },
              {
                name: 'Неактивные',
                value: 'false'
              }
            ]}
            placeholder='Доступность'
            style={{ maxWidth: '300px' }}
            value={active}
          />
          <Select
            onChange={this.handleSelect('sort')}
            options={[
              {
                name: 'Все',
                value: ''
              },
              {
                name: 'По скидке',
                value: 'percentage'
              },
              {
                name: 'По цене',
                value: 'price'
              }
            ]}
            placeholder='Отсортировать'
            style={{ maxWidth: '300px' }}
            value={sort}
          />
        </div>
        <div className='page-actions'>
          <SearchButton
            btnProps={{
              onClick: this.handleSearchByName,
              className: 'btn-primary',
              title: 'Найти'
            }}
            id='name-search'
            inputProps={{
              onChange: this.handleChangeName,
              placeholder: 'Поиск по названию',
              value: this.state.name
            }}
          />
          <Button
            label='Добавить'
            onClick={events.toggleModal}
            style={{ marginLeft: 'auto' }}
          />
        </div>
        <div className='table-container'>
          <Table
            options={[
              {
                name: 'name',
                value: 'Товар'
              },
              {
                name: 'image',
                value: 'Изображение',
                className: 'table__image'
              },
              {
                name: 'cashback',
                value: 'Кэшбек'
              },
              {
                name: 'price',
                value: 'Цена'
              },
              {
                name: 'percentage',
                value: 'Процент скидки'
              },
              {
                name: 'discount',
                value: 'Скидка'
              },
              {
                name: 'dailyLimited',
                value: 'Лимит'
              },
              {
                name: 'active',
                value: 'Доступен'
              },
              {
                name: 'info',
                value: '',
                className: 'modal__info'
              },
              {
                name: 'remove',
                value: '',
                className: 'modal__info'
              }
            ]}
            rows={this.formatData()}
          />
        </div>
        <Modal
          action={{
            name: 'Добавить',
            onClick: this.props.addProduct,
            className: 'btn-primary'
          }}
          addPhoto={this.props.uploadImage}
          defaultState={{
            src: '',
            name: '',
            image: '',
            price: '0',
            percentage: '0',
            discount: '0',
            description: '',
            dailyLimited: true,
            active: true
          }}
          onToggle={events.toggleModal}
          open={values.isModalOpen}
          title='Добавление товара'
        />
        <Modal
          action={{
            name: 'Сохранить',
            onClick: this.props.changeProduct,
            className: 'btn-secondary'
          }}
          addPhoto={this.props.uploadImage}
          defaultState={{
            ...data,
            src: ''
          }}
          edit
          onToggle={events.toggleEditModal}
          open={values.isOpenEditModal}
          title='Информация'
        />
        <Pagination
          limit={PRODUCTS_LIMIT}
          total={this.props.pagination.total}
          value={events.getQueryString('page')}
        />
      </React.Fragment>
    );
  }
}

const Page = withPageContainer(ProductsPage, { limit: PRODUCTS_LIMIT });

const mapStateToProps = state => ({
  products: state.products.items,
  isFetching: state.products.isFetching,
  pagination: state.products.pagination
});

const mapDispatchToProps = dispatch => ({
  uploadImage: (...args) => dispatch(uploadImage(...args)),
  fetch: (...args) => dispatch(fetchProducts(...args)),
  addProduct: (...args) => dispatch(addProduct(...args)),
  changeProduct: (...args) => dispatch(changeProduct(...args)),
  deleteProduct: id => dispatch(deleteProduct(id))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Page)
);
