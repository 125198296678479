import { combineReducers } from 'redux';

import user from './user';
import cities from './cities';
import clients from './clients';
import sellers from './sellers';
import branches from './branches';
import products from './products';
import partners from './partners';
import partnerSales from './partner-sales';
import adminSales from './admin-sales';
import bonuses from './bonuses';
import promocodes from './promocodes';
import settings from './settings';
import partnerTypes from './partnerTypes';

export default combineReducers({
  user,
  cities,
  clients,
  sellers,
  branches,
  products,
  partners,
  adminSales,
  partnerSales,
  bonuses,
  promocodes,
  settings,
  partnerTypes
});
