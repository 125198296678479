import queryString from 'query-string';

import {
  URL,
  REQUEST_PRODUCTS,
  RECEIVE_PRODUCTS,
  PRODUCTS_LIMIT,
  DELETE_ADMIN_PRODUCT
} from '../lib/actions';

import { getQueryParametres } from '../lib/functions';

import { logout } from './user';

function requestProducts() {
  return {
    type: REQUEST_PRODUCTS
  };
}

function receiveProducts(response) {
  return {
    type: RECEIVE_PRODUCTS,
    response
  };
}

export function fetchProducts(query = { page: 1, limit: 7 }, name = 'partner') {
  return async (dispatch, getState) => {
    dispatch(requestProducts());
    const request = await fetch(
      `${URL}/${name}/products?${getQueryParametres(query)}`,
      {
        method: 'GET',
        headers: {
          'x-access-token': getState().user.token
        }
      }
    );
    const { status } = await request;
    if (status === 200) {
      const response = await request.json();
      dispatch(receiveProducts(response));
    } else {
      if (status === 401) {
        dispatch(logout());
      }
    }
  };
}

export function addProduct(data) {
  return async (dispatch, getState) => {
    const request = await fetch(`${URL}/partner/products`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getState().user.token
      },
      body: JSON.stringify(data)
    });
    const { status } = await request;
    const response = await request.json();
    if (status === 200) {
      dispatch(
        fetchProducts({
          ...queryString.parse(window.location.search),
          limit: PRODUCTS_LIMIT
        })
      );
    } else {
      switch (status) {
        case 400:
          alert('Заполните все поля');
          break;

        default:
          alert(response.message);
          break;
      }
    }
    return status;
  };
}

export function changeProduct(data, id, name = 'partner') {
  return async (dispatch, getState) => {
    const request = await fetch(`${URL}/${name}/products/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getState().user.token
      },
      body: JSON.stringify(data)
    });
    const { status } = await request;
    if (status === 200) {
      dispatch(
        fetchProducts(
          {
            ...queryString.parse(window.location.search),
            limit: PRODUCTS_LIMIT
          },
          name
        )
      );
    } else {
      switch (status) {
        case 400:
          alert('Заполните все поля');
          break;

        default:
          break;
      }
    }
    return status;
  };
}

export const deleteProduct = id => async (dispatch, getState) => {
  const request = await fetch(`${URL}/partner/products/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': getState().user.token
    }
  });

  const { status } = await request;

  if (status === 200) {
    dispatch({
      type: DELETE_ADMIN_PRODUCT,
      id
    });
    dispatch(fetchProducts(queryString.parse(window.location.search)));
  }
};
