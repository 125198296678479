import {
  DELETE_PROMOCODE_GROUP,
  GET_PROMOCODES_GROUP_BY_ID,
  GET_PROMOCODES_GROUPS
} from '../lib/actions';

const STATE = {
  items: [],
  itemById: {
    data: [],
    pagination: {}
  },
  pagination: {},
  stat: {}
};

export default function(state = STATE, action) {
  switch (action.type) {
    case GET_PROMOCODES_GROUPS:
      return {
        ...state,
        items: action.response.data,
        ...action.response.meta
      };

    case GET_PROMOCODES_GROUP_BY_ID:
      return {
        ...state,
        itemById: {
          data: action.response.data,
          ...action.response.meta
        }
      };

    case DELETE_PROMOCODE_GROUP:
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.response.id)
      };

    default:
      return state;
  }
}
