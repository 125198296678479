import { REQUEST_PARTNERS, RECEIVE_PARTNERS } from '../lib/actions';
import { PARTNERS, PARTNERS_PAGINATION } from '../lib/storageVariables';
import { getLocalStorage, setLocalStorage } from '../lib/functions';

const STATE = {
  isFetching: false,
  items: getLocalStorage(PARTNERS) || [],
  pagination: getLocalStorage(PARTNERS_PAGINATION) || []
};

export default function(state = STATE, action) {
  switch (action.type) {
    case REQUEST_PARTNERS:
      return {
        ...state,
        isFetching: true
      };

    case RECEIVE_PARTNERS:
      const partners = action.response.data;
      const pagination = action.response.meta
        ? action.response.meta.pagination
        : {};

      if (partners) setLocalStorage(PARTNERS, partners);
      if (pagination) setLocalStorage(PARTNERS_PAGINATION, pagination);

      return {
        ...state,
        isFetching: false,
        items: partners || state.items,
        pagination: pagination || state.pagination
      };

    default:
      return state;
  }
}
