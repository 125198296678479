import queryString from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';

import authSuccessImage from '../image/authSuccess.svg';
import authFailedImage from '../image/authFailed.svg';

import '../scss/pages/authStatusPage.scss';

const AuthStatusPage = () => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const isFailed = query.status === 'failed';
  const image = isFailed ? authFailedImage : authSuccessImage;
  const title = isFailed
    ? 'Авторизация не удалась'
    : 'Авторизация прошла успешно';

  return (
    <div className='authStatusContainer'>
      <img src={image} />
      <div>{title}</div>
    </div>
  );
};

export default AuthStatusPage;
