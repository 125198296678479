import { REQUEST_ADMIN_SALES, RECEIVE_ADMIN_SALES } from '../lib/actions';
import {
  ADMIN_SALES,
  ADMIN_SALES_PAGINATION,
  ADMIN_SALES_STATISTIC
} from '../lib/storageVariables';
import { getLocalStorage, setLocalStorage } from '../lib/functions';

const STATE = {
  isFetching: false,
  items: getLocalStorage(ADMIN_SALES) || [],
  pagination: getLocalStorage(ADMIN_SALES_PAGINATION) || [],
  stats: getLocalStorage(ADMIN_SALES_STATISTIC) || {
    scholls: 0
  }
};

export default function(state = STATE, action) {
  switch (action.type) {
    case REQUEST_ADMIN_SALES:
      return {
        ...state,
        isFetching: true
      };

    case RECEIVE_ADMIN_SALES:
      const sales = action.response.data;
      const { pagination, stats } = action.response.meta;

      if (sales) setLocalStorage(ADMIN_SALES, sales);
      if (pagination) setLocalStorage(ADMIN_SALES_PAGINATION, pagination);
      if (stats) setLocalStorage(ADMIN_SALES_STATISTIC, stats);

      return {
        ...state,
        isFetching: false,
        items: sales || state.items,
        pagination: pagination || state.pagination,
        stats: stats || state.stats
      };

    default:
      return state;
  }
}
