import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import SVG from 'react-svg';
import { shallowEqualObjects } from 'shallow-equal';

import { fileReader } from '../../lib/functions';

import cancel from './cancel.svg';
import './style.scss';

class modal extends PureComponent {
  static defaultProps = {
    className: '',
    title: 'Title',
    renderContent: () => {},
    action: null,
    actionPatch: null,
    actionName: 'Save'
  };

  state = this._initState();

  componentDidUpdate(prevProps) {
    if (document.querySelector('.new-modal.visible')) {
      document.body.classList.add('scroll-brake');
    } else {
      document.body.classList.remove('scroll-brake');
    }

    if (JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)) {
      this.setState(prevProps.data);
    }

    if (this.props.open && !prevProps.open) {
      this.setState(this._initState());
    }
  }

  @bind
  _initState() {
    const { data } = this.props;
    if (data) return { data: {}, ...this.props.data };
    return { data: {} };
  }

  @bind
  handleLoadFile(filename, userType) {
    return () => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/png,image/jpeg';

      input.addEventListener('change', async event => {
        const file = Array.from(event.currentTarget.files)[0];
        const src = await fileReader(file);
        const response = await this.props.uploadPhoto(file, userType);
        let photo = '';

        if (response) {
          photo = response.data.filename;
        }

        if (photo) {
          this.setState({
            src,
            data: {
              ...this.state.data,
              [filename]: photo
            }
          });
        }
      });

      input.click();
    };
  }

  @bind
  handleSelect(name) {
    return value =>
      this.setState({ data: { ...this.state.data, [name]: value } });
  }

  @bind
  handleChange(name, nestedName) {
    return e => {
      const { state } = this;
      let { value } = e.target;
      const { type } = e.target.dataset;

      if (value.length !== 0 && type === 'number') {
        const nextVal = parseInt(value, 10);
        value = !Number.isNaN(nextVal) ? nextVal : '';
      }

      if (nestedName)
        this.setState({
          data: {
            ...state.data,
            [nestedName]: {
              ...state[nestedName],
              [name]: value
            }
          }
        });
      else
        this.setState({
          data: {
            ...state.data,
            [name]: value
          }
        });
    };
  }

  @bind
  async handleSubmit(type) {
    const data = { ...this.state.data };

    if (this.props.onClose) {
      const shouldClose = await this.props.onClose(data, type);
      return shouldClose && this.props.close();
    }

    const callback = (response, status) => {
      if (status === 200) this.props.close();
      else alert(response.message);
    };

    const { action, actionPatch } = this.props;

    if (data.id && actionPatch) actionPatch(data, data.id, callback);
    else if (action) action(data, callback);
  }

  @bind
  handleSelectMultiple(name) {
    return value => {
      const nextValue = [...this.state.data[name]];
      const matchId = nextValue.indexOf(value);

      if (matchId !== -1) nextValue.splice(matchId, 1);
      else nextValue.push(value);

      this.setState({ data: { ...this.state.data, [name]: nextValue } });
    };
  }

  @bind
  handleCheck(name) {
    return () => {
      this.setDataState({ [name]: !this.state.data[name] });
    };
  }

  @bind
  handleClose() {
    if (!this.props.data) return this.props.close();
    if (shallowEqualObjects(this.props.data.data, this.state.data)) {
      this.props.close();
    } else if (window.confirm('Закрыть без сохранения?')) {
      this.props.close();
    }
  }

  @bind
  setDataState(state) {
    this.setState({
      data: {
        ...this.state.data,
        ...state
      }
    });
  }

  @bind
  formatDataToSelect(prop) {
    if (this.state[prop]) {
      return this.state[prop].map(item => ({
        name: item.name,
        value: item.id
      }));
    }

    return [];
  }

  @bind
  getClassName() {
    let className = 'new-modal';
    if (this.props.open) className += ' visible';
    if (this.props.className) className += ` ${this.props.className}`;
    return className;
  }

  @bind
  renderActions() {
    const { renderActions } = this.props;
    if (!renderActions || !renderActions(this)) return null;

    return <div className='modal-footer'>{renderActions(this)}</div>;
  }

  @bind
  renderContent() {
    const { close, title, renderContent, children } = this.props;

    return (
      <div className='modal-content'>
        <div className='modal-title'>
          {title}
          <div
            className='modal-cancel'
            onClick={this.handleClose}
          >
            <SVG src={cancel} />
          </div>
        </div>
        <div className='modal-body'>{renderContent(this) || children}</div>
        {this.renderActions()}
      </div>
    );
  }

  render() {
    return ReactDOM.createPortal(
      <div className={this.getClassName()}>
        {this.renderContent()}
        <div
          className='modal-fade'
          onClick={this.handleClose}
        />
      </div>,
      document.body
    );
  }
}

export default modal;
