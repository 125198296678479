import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import queryString from 'query-string';

import MetaStat from '../components/MetaStat';
import Datepicker from '../components/RangeDatePicker';

import Select from '../components/Select';

import Table from '../components/table';
import Checkbox from '../components/checkbox';
import Pagination from '../components/oldPagination';
import EditModal from '../modals/clients-editModal';
import SearchButton from '../components/SearchButton';

import { fetchClients, deleteClient, changeClient } from '../actions/clients';
import { getUpload } from '../actions/export';

import { CLIENTS_LIMIT } from '../lib/actions';

import withPageContainer from '../components/page';

import {
  formatDateToOutput,
  formatPhone,
  formatPhoneForQuery,
  getQueryString,
  withConfirm
} from '../lib/functions';
import routes from '../routes/routes';

class ClientsPage extends React.Component {
  state = { phone: '' };

  @bind
  handleSelect(name) {
    return value => {
      this.pushHistory({ [name]: value, page: 1 });
    };
  }

  @bind
  handleToggleActive(client) {
    return () => {
      this.props.changeClient({ active: !client.active }, client.id);
    };
  }

  @bind
  onDate(from, to) {
    this.pushHistory({
      from,
      to
    });
  }

  @bind
  formatData() {
    return this.props.clients.map(({ ...client }, id) => ({
      ...client,
      name: `${client.lastName} ${client.firstName} ${client.middleName || ''}`,
      createdAt: formatDateToOutput(client.timestamp),
      active: (
        <Checkbox
          onChange={this.handleToggleActive(client)}
          value={client.active}
        />
      ),
      phone: (
        <Link
          className='btn-reset btn-link'
          to={`${routes.salesPage.path}?clientPhone=${client.phone.replace(
            /^\+7/,
            ''
          )}`}
        >
          {client.phone}
        </Link>
      ),
      info: (
        <button
          className='btn-reset btn-link'
          onClick={() => this.props.events.toggleEditModal(id)}
        >
          Инфо
        </button>
      ),
      remove: (
        <button
          className='btn-reset btn-link'
          onClick={() =>
            withConfirm(
              this.props.deleteClient,
              'Удалить пользователя?'
            )(client.id)
          }
        >
          Удалить
        </button>
      )
    }));
  }

  @bind
  pushHistory(query) {
    const { history } = this.props;
    history.push(
      `${history.location.pathname}?${queryString.stringify({
        ...getQueryString(),
        ...query
      })}`
    );
  }

  @bind
  handleChangePhone(event) {
    const value = formatPhone(event.target.value);
    this.setState({ phone: value });
  }

  @bind
  handleSearchByPhone() {
    const value = this.state.phone.length !== 18 ? '' : this.state.phone;
    this.pushHistory({
      phone: formatPhoneForQuery(value),
      page: 1
    });
  }

  getModalData = () => ({
    addBonuses: '',
    removeBonuses: ''
  });

  render() {
    const { events, values } = this.props;
    const data = {
      ...this.getModalData(),
      ...this.props.clients[values.editModalId]
    };
    const { phone = '', sort = '', active = '' } = getQueryString();

    return (
      <>
        <div className='header'>
          <h2 className='h2'>Пользователи</h2>
          <button
            className='header__button btn-secondary'
            onClick={() => this.props.getUpload('admin/clients')}
          >
            Экспорт
          </button>
        </div>
        <div className='page-actions'>
          <SearchButton
            btnProps={{
              onClick: this.handleSearchByPhone,
              className: 'btn-primary',
              title: 'Найти'
            }}
            inputProps={{
              mask: '+7 (999) 999 99 99',
              maskChar: '',
              onChange: this.handleChangePhone,
              placeholder: 'Поиск по телефону клиента',
              value: this.state.phone || formatPhone(phone)
            }}
          />
          <Select
            onChange={this.handleSelect('sort')}
            options={[
              {
                name: 'Все',
                value: ''
              },
              {
                name: 'По балансу',
                value: 'bonus'
              },
              {
                name: 'По доступности',
                value: 'active'
              },
              {
                name: 'По дате регистрации',
                value: 'timestamp'
              }
            ]}
            placeholder='Отсортировать'
            style={{ maxWidth: '300px' }}
            value={sort}
          />
          <Select
            onChange={this.handleSelect('active')}
            options={[
              {
                name: 'Все',
                value: ''
              },
              {
                name: 'Достуные',
                value: 'true'
              },
              {
                name: 'Не доступные',
                value: 'false'
              }
            ]}
            placeholder='По доступности'
            style={{ maxWidth: '300px' }}
            value={active}
          />
          <Datepicker onChange={this.onDate} />
        </div>
        <div className='page-stats'>
          <MetaStat
            label='Всего пользователей'
            value={this.props.pagination.total}
          />
          <MetaStat
            label='С дневником'
            value={this.props.pagination.withName}
          />
          <MetaStat
            label='Без дневника'
            value={this.props.pagination.withoutName}
          />
        </div>
        <div className='table-container'>
          <Table
            options={[
              { name: 'name', value: 'ФИО' },
              { name: 'createdAt', value: 'Дата регистрации' },
              { name: 'bonus', value: 'Баланс' },
              { name: 'phone', value: 'Телефон' },
              { name: 'active', value: 'Доступен' },
              { name: 'info', value: '', className: 'modal__info' },
              { name: 'remove', value: '', className: 'modal__info' }
            ]}
            rows={this.formatData()}
          />
        </div>
        <EditModal
          action={{
            name: 'Сохранить',
            onClick: this.props.changeClient,
            className: 'btn-primary'
          }}
          defaultState={data}
          onToggle={events.toggleEditModal}
          open={values.isOpenEditModal}
          title='Информация'
        />
        <Pagination
          limit={CLIENTS_LIMIT}
          total={this.props.pagination.total}
          value={events.getQueryString('page')}
        />
      </>
    );
  }
}

const Page = withPageContainer(ClientsPage, { limit: CLIENTS_LIMIT });

const mapStateToProps = state => ({
  clients: state.clients.items,
  isFetching: state.clients.isFetching,
  pagination: state.clients.pagination,
  stats: state.clients.stats
});

const mapDispatchToProps = dispatch => ({
  fetch: (...args) => dispatch(fetchClients(...args)),
  getUpload: (...args) => dispatch(getUpload(...args)),
  deleteClient: id => dispatch(deleteClient(id)),
  changeClient: (...args) => dispatch(changeClient(...args))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Page));
