import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { login } from '../../actions/user';
import Button from '../Button';

import Input from '../Input';

class Authorization extends React.Component {
  static propTypes = {
    login: PropTypes.func.isRequired
  };

  state = {};

  @bind
  handleChange(name) {
    return event => {
      this.setState({ [name]: event.target.value });
    };
  }

  @bind
  handleSubmit(event) {
    event.preventDefault();
    const path = window.location.pathname.includes('admin')
      ? 'admin'
      : 'partner';
    this.props.login(this.state, path);
  }

  renderAdminAuth() {
    const { username, password } = this.state;

    return (
      <>
        <Input
          label='Логин'
          onChange={this.handleChange('username')}
          value={username}
        />
        <Input
          label='Пароль'
          onChange={this.handleChange('password')}
          type='password'
          value={password}
        />
      </>
    );
  }

  renderPartnerAuth() {
    const { email, password } = this.state;

    return (
      <>
        <Input
          label='Email'
          onChange={this.handleChange('email')}
          value={email}
        />
        <Input
          label='Пароль'
          onChange={this.handleChange('password')}
          type='password'
          value={password}
        />
      </>
    );
  }

  render() {
    const isPartner = !window.location.pathname.includes('admin');

    return (
      <div className='login-container'>
        <form
          className='login-form'
          onSubmit={this.handleSubmit}
        >
          {isPartner ? this.renderPartnerAuth() : this.renderAdminAuth()}
          <Button
            label='Войти'
            type='submit'
          />
        </form>
      </div>
    );
  }
}

const mapDispatch = {
  login
};

export default connect(
  null,
  mapDispatch
)(Authorization);
