import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { createPartnerType, getPartnerTypes } from '../actions/partnerTypes';

import Button from '../components/Button';

import Modal from '../components/Modal';
import Input from '../components/Input';
import useLegacyState from '../hooks/useLegacyState';

const PartnerTypesModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();

  const [state, setState] = useLegacyState({
    name: ''
  });

  useEffect(() => {
    if (!isOpen) {
      setState({
        name: ''
      });
    }
  }, [isOpen]);

  const handleSubmit = () => {
    const callback = () => {
      onClose();
      dispatch(getPartnerTypes());
    };

    dispatch(createPartnerType(state, callback));
  };

  return (
    <Modal
      close={onClose}
      open={isOpen}
      title='Тип партнера'
    >
      <Input
        label='Название'
        onChange={e => setState({ name: e.target.value })}
        value={state.name}
      />
      <Button
        label='Сохранить'
        onClick={handleSubmit}
      />
    </Modal>
  );
};

export default PartnerTypesModal;
