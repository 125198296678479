export const USER_TOKEN = 'USER_TOKEN';
export const PARTNER_INFO = 'PARTNER_INFO';

export const PARTNERS = 'PARTNERS';
export const PARTNERS_PAGINATION = 'PARTNERS_PAGINATION';

export const PRODUCTS = 'PRODUCTS';
export const PRODUCTS_PAGINATION = 'PRODUCTS_PAGINATION';

export const BRANCHES = 'BRANCHES';
export const BRANCHES_PAGINATION = 'BRANCHES_PAGINATION';

export const SELLERS = 'SELLERS';
export const SELLERS_PAGINATION = 'SELLERS_PAGINATION';

export const SALES = 'SALES';
export const SALES_PAGINATION = 'SALES_PAGINATION';
export const SALES_STATISTIC = 'SALES_STATISTIC';

export const CITIES = 'CITIES';
export const CITIES_PAGINATION = 'CITIES_PAGINATION';
export const CITIES_STATISTIC = 'CITIES_STATISTIC';

export const CLIENTS = 'CLIENTS';
export const CLIENTS_PAGINATION = 'CLIENTS_PAGINATION';
export const CLIENTS_STATISTIC = 'CLIENTS_STATISTIC';

export const ADMIN_SALES = 'ADMIN_SALES';
export const ADMIN_SALES_PAGINATION = 'ADMIN_SALES_PAGINATION';
export const ADMIN_SALES_STATISTIC = 'ADMIN_SALES_STATISTIC';

export const BONUSES = 'BONUSES';
export const BONUSES_PAGINATION = 'BONUSES_PAGINATION';
export const BONUSES_STATISTIC = 'BONUSES_STATISTIC';
export const BONUSES_FILTERS = 'BONUSES_FILTERS';
