import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SVG from 'react-svg';

import Table from '../components/table';
import Modal from '../modals/branches-modal';
import Checkbox from '../components/checkbox';
import Pagination from '../components/oldPagination';

import {
  fetchBranches,
  addBranch,
  changeBranch,
  deleteBranch
} from '../actions/branches';

import { BRANCHES_LIMIT } from '../lib/actions';

import withPageContainer from '../components/page';

import trash from '../image/trash.svg';

class BranchesPage extends React.Component {
  @bind
  formatData() {
    return this.props.branches.map((branch, id) => ({
      ...branch,
      active: <Checkbox value={branch.active} />,
      info: (
        <button
          className='btn-reset btn-link'
          onClick={() => this.props.events.toggleEditModal(id)}
        >
          Инфо
        </button>
      ),
      delete: (
        <button
          className='btn-reset btn-link'
          onClick={this.deleteBranch(branch.id)}
        >
          <SVG src={trash} />
        </button>
      )
    }));
  }

  @bind
  deleteBranch(id) {
    return () => {
      if (window.confirm('Удалить филиал?')) {
        this.props.deleteBranch(id);
      }
    };
  }

  render() {
    const { events, values } = this.props;
    const data = this.props.branches[values.editModalId] || {};

    return (
      <React.Fragment>
        <div className='header'>
          <h2 className='h2'>Филиалы</h2>
        </div>
        <div className='control-block'>
          <div className='control-block__line control-block__line_just-cont_end'>
            <button
              className='control-block__btn btn-primary'
              onClick={events.toggleModal}
            >
              Добавить
            </button>
          </div>
        </div>
        <div className='table-container'>
          <Table
            options={[
              { name: 'city', value: 'Города' },
              { name: 'address', value: 'Адрес' },
              { name: 'workTime', value: 'Рабочее время' },
              { name: 'phone', value: 'Телефон', className: 'table__nowrap' },
              { name: 'active', value: 'Доступен' },
              { name: 'info', value: '', className: 'modal__info' },
              { name: 'delete', value: '', className: 'modal__info' }
            ]}
            rows={this.formatData()}
          />
        </div>
        <Modal
          action={{
            name: 'Добавить',
            onClick: this.props.addBranch,
            className: 'btn-primary'
          }}
          defaultState={{
            city: '',
            address: '',
            workTime: '',
            phone: '',
            lat: '',
            lon: '',
            active: true
          }}
          onToggle={events.toggleModal}
          open={values.isModalOpen}
          title='Добавление филиала'
        />
        <Modal
          action={{
            name: 'Сохранить',
            onClick: this.props.changeBranch,
            className: 'btn-secondary'
          }}
          defaultState={data}
          edit
          onToggle={events.toggleEditModal}
          open={values.isOpenEditModal}
          title='Информация'
        />
        <Pagination
          limit={BRANCHES_LIMIT}
          total={this.props.pagination.total}
          value={events.getQueryString('page')}
        />
      </React.Fragment>
    );
  }
}

const Page = withPageContainer(BranchesPage, { limit: BRANCHES_LIMIT });

const mapStateToProps = state => ({
  branches: state.branches.items,
  isFetching: state.branches.isFetching,
  pagination: state.branches.pagination
});

const mapDispatchToProps = dispatch => ({
  addBranch: (...args) => dispatch(addBranch(...args)),
  deleteBranch: id => dispatch(deleteBranch(id, 'partner')),
  changeBranch: (...args) => dispatch(changeBranch(...args)),
  fetch: (...args) => dispatch(fetchBranches(...args))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Page));
