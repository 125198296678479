import PromocodesPage from '../pages/promocodesPage';
import PromocodePage from '../pages/promocodePage';

import PartnersPage from '../pages/partners';
import ClientsPage from '../pages/clients';
import SalesPage from '../pages/admin-sales';
import BonusesPage from '../pages/bonuses';
import ProductsPage from '../pages/admin-products';
import BranchesPage from '../pages/admin-branches';
import SettingsPage from '../pages/settings';
import PartnerProductsPage from '../pages/products';
import PartnerBranchesPage from '../pages/branches';
import SellersPage from '../pages/sellers';
import PartnerSalesPage from '../pages/partner-sales';
import AuthStatusPage from '../pages/authStatus';

const routes = {
  partnerProductsPage: {
    component: PartnerProductsPage,
    path: '/products'
  },
  partnerBranchesPage: {
    component: PartnerBranchesPage,
    path: '/branches'
  },
  sellersPage: {
    component: SellersPage,
    path: '/employees'
  },
  partnerSalesPage: {
    component: PartnerSalesPage,
    path: '/'
  },
  promocodesPage: {
    component: PromocodesPage,
    path: ['/admin/promocodes', '/promocodes']
  },
  promocodePage: {
    component: PromocodePage,
    path: ['/admin/promocodes/:id', '/promocodes/:id']
  },
  partnersPage: {
    component: PartnersPage,
    path: '/admin/partners'
  },
  clientsPage: {
    component: ClientsPage,
    path: '/admin/users'
  },
  salesPage: {
    component: SalesPage,
    path: '/admin'
  },
  bonusesPage: {
    component: BonusesPage,
    path: '/admin/bonuses'
  },
  productPage: {
    component: ProductsPage,
    path: '/admin/products'
  },
  branchesPage: {
    component: BranchesPage,
    path: '/admin/places'
  },
  settingsPage: {
    component: SettingsPage,
    path: ['/admin/settings', '/settings']
  },
  authStatusPage: {
    component: AuthStatusPage,
    path: ['/admin/authstatus', '/authstatus'],
    withoutMenu: true
  }
};

export default routes;
