import { REQUEST_BONUSES, RECEIVE_BONUSES } from '../lib/actions';
import {
  BONUSES,
  BONUSES_PAGINATION,
  BONUSES_STATISTIC,
  BONUSES_FILTERS
} from '../lib/storageVariables';
import { getLocalStorage, setLocalStorage } from '../lib/functions';

const STATE = {
  isFetching: false,
  items: getLocalStorage(BONUSES) || [],
  pagination: getLocalStorage(BONUSES_PAGINATION) || {},
  stats: getLocalStorage(BONUSES_STATISTIC) || {},
  filters: getLocalStorage(BONUSES_FILTERS) || {
    subjects: [],
    schools: [],
    cities: [],
    types: []
  }
};

export default function(state = STATE, action) {
  switch (action.type) {
    case REQUEST_BONUSES:
      return {
        ...state,
        isFetching: true
      };

    case RECEIVE_BONUSES:
      const bonuses = action.response.data;
      const { pagination, stats } = action.response.meta;

      if (bonuses) setLocalStorage(BONUSES, bonuses);
      if (pagination) setLocalStorage(BONUSES_PAGINATION, pagination);
      if (stats) setLocalStorage(BONUSES_STATISTIC, stats);

      return {
        ...state,
        isFetching: false,
        items: bonuses || state.items,
        pagination: pagination || state.pagination,
        stats: stats || state.stats
      };

    case 'GET_FILTERS':
      const filters = action.response.data;
      if (filters) setLocalStorage(BONUSES_FILTERS, filters);

      return {
        ...state,
        filters: filters || state.filters
      };

    default:
      return state;
  }
}
