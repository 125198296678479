import React, { Component } from 'react';
import ReactPagination from 'react-paginate';

import { withRouter } from 'react-router-dom';

import { getQueryString, pushHistory } from '../../lib/functions';

import styles from './style.scss';

const ITEMS_COUNT = 10;

class Pagination extends Component {
  pushHistory = pushHistory.bind(this);

  @bind
  changePage({ selected }) {
    const page = selected + 1;
    this.pushHistory({ page });
  }

  render() {
    const { count } = this.props;

    if (!count || count <= ITEMS_COUNT) return null;

    let initialPage = 1;
    const page = getQueryString('page');
    if (page === 1) initialPage = 0;
    else initialPage = page - 1;

    return (
      <ReactPagination
        activeClassName='active'
        containerClassName='pagination'
        initialPage={initialPage}
        marginPagesDisplayed={1}
        nextClassName='next'
        nextLabel='Next'
        onPageChange={this.changePage}
        pageCount={Math.ceil(count / ITEMS_COUNT)}
        pageRangeDisplayed={4}
        previousClassName='previous'
        previousLabel='Prev'
      />
    );
  }
}

export default withRouter(Pagination);
