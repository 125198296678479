import React from 'react';

import './style.scss';

export default props => (
  <table className={`table ${props.className || ''}`}>
    <thead className='thead'>
      <tr className='tr'>
        {props.options.map((option, i) => (
          <th
            className={`th${option.className ? ` ${option.className}` : ''}`}
            key={i}
          >
            {option.value}
          </th>
        ))}
      </tr>
    </thead>
    <tbody className='tbody'>
      {props.rows.map((item, i) => (
        <tr
          className='tr'
          key={i}
        >
          {props.options.map((option, j) => (
            <td
              className={`td${option.className ? ` ${option.className}` : ''}`}
              key={j}
            >
              {item[option.name]}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);
