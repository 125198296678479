import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import MetaStat from '../components/MetaStat';

import withPageContainer from '../components/page';
import Table from '../components/table';
import Pagination from '../components/oldPagination';
import EditModal from '../modals/bonuses-editModal';
import SearchButton from '../components/SearchButton';
import Datepicker from '../components/RangeDatePicker';
import Select from '../components/Select';

import { fetchBonuses, getFilters } from '../actions/bonuses';
import { fetchClients } from '../actions/clients';
import { getUpload } from '../actions/export';

import {
  getQueryString,
  formatPhone,
  formatPhoneForQuery,
  formatDateToOutput,
  pushHistory
} from '../lib/functions';

import '../scss/pages/bonusesPage.scss';

const mapItem = item => ({
  name: item,
  value: item
});

class BonusesPage extends React.Component {
  state = {
    clientPhone: ''
  };

  componentDidMount() {
    this.props.fetchClients();

    const { city, from, to } = getQueryString();
    this.props.getFilters({ city });
    if (!from || !to) this.setDefaultDate();
  }

  componentDidUpdate(prevProps) {
    const prevCity = getQueryString('city', prevProps.location.search);
    const city = getQueryString('city');

    if (city !== prevCity) {
      this.props.getFilters({ city });
    }
  }

  pushHistory = pushHistory.bind(this);

  @bind
  setDefaultDate() {
    const from = moment()
      .add(-6, 'month')
      .toISOString();
    const to = moment().toISOString();

    this.pushHistory({
      from,
      to
    });
  }

  @bind
  handleSelect(name) {
    return value => {
      const query = {
        [name]: value,
        page: 1
      };

      if (name === 'city') {
        query.school = '';
      }

      this.pushHistory(query);
    };
  }

  @bind
  handleChangePhone(event) {
    const value = formatPhone(event.target.value);
    this.setState({ clientPhone: value });
  }

  @bind
  handleSearchByPhone() {
    const value =
      this.state.clientPhone.length !== 18 ? '' : this.state.clientPhone;
    this.pushHistory({
      clientPhone: formatPhoneForQuery(value),
      page: 1
    });
  }

  @bind
  handleChangeDate(from, to) {
    this.pushHistory({
      from,
      to
    });
  }

  @bind
  formatData() {
    return this.props.bonuses.map((bonus, id) => ({
      ...bonus,
      date: formatDateToOutput(bonus.createdAt),
      clientPhone: bonus.client ? bonus.client.phone : '',
      info: (
        <button
          className='btn-reset btn-link'
          onClick={() => this.props.events.toggleEditModal(id)}
        >
          Инфо
        </button>
      )
    }));
  }

  @bind
  formatClients() {
    return [
      {
        name: 'Все',
        value: ''
      },
      ...this.props.clients.map(client => ({
        name: `${client.lastName} ${client.firstName} ${client.middleName}`,
        value: client.id
      }))
    ];
  }

  @bind
  formatSchools() {
    return [
      {
        name: 'Все',
        value: ''
      },
      ...this.props.filters.schools.map(mapItem)
    ];
  }

  @bind
  formatCities() {
    return [
      {
        name: 'Все',
        value: ''
      },
      ...this.props.filters.cities.map(mapItem)
    ];
  }

  @bind
  formatTypes() {
    return [
      {
        name: 'Все',
        value: ''
      },
      ...this.props.filters.types.map(mapItem)
    ];
  }

  @bind
  formatSubjects() {
    return [
      {
        name: 'Все',
        value: ''
      },
      ...this.props.filters.subjects.map(mapItem)
    ];
  }

  @bind
  serviceTypeOptions() {
    return [
      {
        name: 'Все',
        value: ''
      },
      {
        name: 'Дневник',
        value: 'journal'
      },
      {
        name: 'Элжур РУ',
        value: 'eljur'
      },
      {
        name: 'Элжур-НН',
        value: 'eljur_nn'
      }
    ];
  }

  render() {
    const { events, values } = this.props;
    const data = this.props.bonuses[values.editModalId] || {};
    const {
      clientPhone = '',
      school = '',
      city = '',
      type = '',
      subject = '',
      serviceType = '',
      from = '',
      to = ''
    } = getQueryString();

    return (
      <React.Fragment>
        <div className='header'>
          <h2 className='h2'>Бонусы</h2>
          <button
            className='btn-secondary header__button'
            onClick={() => this.props.getUpload('admin/bonuses')}
          >
            Экспорт
          </button>
        </div>
        <div className='page-actions'>
          <SearchButton
            btnProps={{
              onClick: this.handleSearchByPhone,
              className: 'btn-primary',
              title: 'Найти'
            }}
            id='clientPhone-search'
            inputProps={{
              mask: '+7 (999) 999 99 99',
              maskChar: '',
              onChange: this.handleChangePhone,
              placeholder: 'Поиск по телефону клиента',
              value: this.state.clientPhone || formatPhone(clientPhone)
            }}
          />
          <Datepicker
            from={from}
            onChange={this.handleChangeDate}
            to={to}
          />
        </div>
        <div className='page-actions'>
          <Select
            className='city-filter'
            onChange={this.handleSelect('city')}
            options={this.formatCities()}
            placeholder='Города'
            search
            value={city}
          />
          <Select
            className='school-filter'
            onChange={this.handleSelect('school')}
            options={this.formatSchools()}
            placeholder='Школы'
            search
            value={school}
          />
        </div>
        <div className='page-actions'>
          <Select
            onChange={this.handleSelect('type')}
            options={this.formatTypes()}
            placeholder='Типы'
            search
            value={type}
          />
          <Select
            onChange={this.handleSelect('subject')}
            options={this.formatSubjects()}
            placeholder='Категории'
            search
            value={subject}
          />
          <Select
            onChange={this.handleSelect('serviceType')}
            options={this.serviceTypeOptions()}
            placeholder='Источник'
            search
            value={serviceType}
          />
        </div>
        <div className='page-stats'>
          <MetaStat
            label='Всего бонусов'
            value={this.props.stats.totalBonusAwarded}
          />
          <MetaStat
            label='Всего городов'
            value={this.props.stats.cities}
          />
          <MetaStat
            label='Всего школ'
            value={this.props.stats.schools}
          />
          <MetaStat
            label='Пользователей'
            value={this.props.stats.clients}
          />
        </div>
        <div className='table-container'>
          <Table
            options={[
              {
                name: 'city',
                value: 'Город'
              },
              {
                name: 'school',
                value: 'Школа'
              },
              {
                name: 'type',
                value: 'Тип'
              },
              {
                name: 'amount',
                value: 'Бонусы'
              },
              {
                name: 'clientPhone',
                value: 'Пользователь'
              },
              {
                name: 'date',
                value: 'Дата'
              }
            ]}
            rows={this.formatData()}
          />
        </div>
        <EditModal
          defaultState={data}
          onToggle={events.toggleEditModal}
          open={values.isOpenEditModal}
          title='Информация'
        />
        <Pagination
          total={this.props.pagination.total}
          value={events.getQueryString('page')}
        />
      </React.Fragment>
    );
  }
}

const Page = withPageContainer(BonusesPage, { className: 'bonuses' });

const mapStateToProps = state => ({
  bonuses: state.bonuses.items,
  isFetching: state.bonuses.isFetching,
  pagination: state.bonuses.pagination,
  clients: state.clients.items,
  stats: state.bonuses.stats,
  filters: state.bonuses.filters
});

const mapDispatchToProps = dispatch => ({
  fetch: (...args) => dispatch(fetchBonuses(...args)),
  getUpload: (...args) => dispatch(getUpload(...args)),
  fetchClients: (...args) => dispatch(fetchClients(...args)),
  getFilters: query => dispatch(getFilters(query))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Page)
);
