import React from 'react';
import InputMask from 'react-input-mask';

import Dadata from '../../components/Dadata';

import Checkbox from '../../components/checkbox';
import Input from '../../components/oldInput';

import { withModalContainer } from '../../components/oldModal';

export default withModalContainer(({ values, events, edit }) => {
  const handleSuggest = suggest => {
    if (suggest) {
      events.defaultChangeHandler({
        city: suggest.data.city || '',
        lat: suggest.data.geo_lat,
        lon: suggest.data.geo_lon
      });
    }
  };

  return (
    <>
      <Dadata
        additionalQuery={{
          from_bound: { value: 'city' },
          to_bound: { value: 'city' }
        }}
        inputProps={{
          required: true,
          disabled: values.allRussia
        }}
        label='Город'
        onChange={city => events.defaultChangeHandler({ city })}
        onSuggest={handleSuggest}
        value={values.city}
      />
      <Dadata
        inputProps={{
          disabled: values.allRussia
        }}
        label='Адрес'
        onChange={address => events.defaultChangeHandler({ address })}
        onSuggest={handleSuggest}
        value={values.address}
      />
      <Input
        inputProps={{
          onChange: events.handleChange('workTime'),
          value: values.workTime
        }}
        label='Рабочее время'
      />
      <Input
        inputComponent={InputMask}
        inputProps={{
          mask: '+7 (999) 999 99 99',
          maskChar: '',
          onChange: events.handleChange('phone'),
          value: values.phone
        }}
        label='Телефон'
      />
      <Checkbox
        className='form-control'
        label='Вся россия'
        onChange={events.handleCheck('allRussia')}
        value={values.allRussia}
      />
      {edit && (
        <Checkbox
          className='form-control'
          label='Доступен'
          onChange={events.handleCheck('active')}
          value={values.active}
        />
      )}
    </>
  );
});
