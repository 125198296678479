import React from 'react';

import { getSuggestions as getSuggestionsAsync } from '../../actions/dadata';
import Input from '../Input';

import './style.scss';

const Suggestions = ({ items, onClear, onSuggest, getSuggestions }) => {
  React.useEffect(() => {
    const listener = () => {
      onClear();
    };

    if (items.length) {
      document.addEventListener('click', listener);
    }

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [items.length, onClear]);

  const handleClick = React.useCallback(
    suggest => async () => {
      const response = await getSuggestions(suggest.value, 1);
      onSuggest(response.suggestions[0] || null);
    },
    [onSuggest]
  );

  return (
    <div className='suggestions'>
      {items.map(suggest => (
        <div
          className='suggest'
          key={suggest.value}
          onClick={handleClick(suggest)}
        >
          {suggest.value}
        </div>
      ))}
    </div>
  );
};

const Dadata = ({
  label,
  value,
  onChange,
  onSuggest,
  additionalQuery,
  inputProps
}) => {
  const [inputValue, setInputValue] = React.useState('');
  const [suggestions, setSuggestions] = React.useState([]);

  React.useEffect(() => {
    setInputValue(value);
  }, [value]);

  const getSuggestions = React.useCallback(
    (query, count) =>
      getSuggestionsAsync(
        {
          ...additionalQuery,
          query
        },
        count
      ),
    [additionalQuery]
  );

  const handleChange = React.useCallback(
    async e => {
      const nextValue = e.target.value;
      onChange(nextValue);
      const response = await getSuggestions(nextValue);
      setSuggestions(response.suggestions);
    },
    [onChange, setSuggestions]
  );

  const handleClear = React.useCallback(() => {
    setSuggestions([]);
  }, [setSuggestions]);

  const handleSuggest = React.useCallback(
    suggest => {
      if (onSuggest) {
        onSuggest(suggest);
      }

      onChange(suggest.value);
    },
    [onChange, onSuggest]
  );

  return (
    <div className='dadata'>
      <Input
        {...inputProps}
        autoComplete='address-new'
        label={label}
        onChange={handleChange}
        value={inputValue}
      />
      <Suggestions
        getSuggestions={getSuggestions}
        items={suggestions}
        onClear={handleClear}
        onSuggest={handleSuggest}
      />
    </div>
  );
};

export default Dadata;
