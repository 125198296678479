import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Switch, Route, withRouter } from 'react-router-dom';

import { getPartnerInfo } from '../actions/user';

import { clearPartnerInfoAction } from '../lib/actions';

import LeftMenu from '../components/left-menu';
import { getUserType } from '../lib/functions';

import withToken from '../lib/HOC/withToken';

import Routes from './routes';

class AppRoute extends Component {
  componentDidMount() {
    if (getUserType() === 'partner') this.props.getPartnerInfo();
    else this.props.clearPartnerInfoAction();
  }

  @bind
  routeRender(route) {
    return props => {
      const RouteComponent = route.component;
      return <RouteComponent {...props} />;
    };
  }

  render() {
    const { pathname } = this.props.location;
    const result = [];
    let withMenu = true;

    for (const key in Routes) {
      if (Routes.hasOwnProperty(key)) {
        const route = Routes[key];
        const isStringPath = typeof route.path === 'string';
        const $key = isStringPath ? route.path : route.path[0];
        const isCurrentPath = isStringPath
          ? route.path === pathname
          : route.path.includes(pathname);
        if (isCurrentPath) withMenu = !route.withoutMenu;

        result.push(
          <Route
            exact
            key={$key}
            path={route.path}
            render={this.routeRender(route)}
          />
        );
      }
    }

    return (
      <>
        {withMenu && <LeftMenu />}
        <Switch>{result}</Switch>
      </>
    );
  }
}

const mapDispatch = {
  getPartnerInfo,
  clearPartnerInfoAction
};

export default withToken(withRouter(connect(null, mapDispatch)(AppRoute)));
