import { store } from '../app';

import { getQueryParametres } from './functions';

export const API = process.env.REACT_APP_API_URL + '/api';

const defaultErrors = {
  500: 'Ошибка сервера',
  504: 'Превышено время ожидания ответа от сервера'
};

const request = async (pathname, options) => {
  const {
    done,
    body,
    query,
    errors,
    failed,
    headers,
    success,
    method = 'GET',
    isAbsolutePath,
    successStatuses,
    isBlob
  } = options;

  let path = `${API}${pathname}`;
  if (isAbsolutePath) path = pathname;
  if (query) path += `?${getQueryParametres(query)}`;

  const requestOptions = { method };

  if (body) requestOptions.body = body;
  if (headers) requestOptions.headers = headers;
  else requestOptions.headers = { 'Content-Type': 'application/json' };

  const state = store.getState();
  if (state) {
    requestOptions.headers['x-access-token'] = state.user.token;
  }

  try {
    const serverRequest = await fetch(path, requestOptions);

    let response = { status: 'Development request error' };

    if (isBlob) {
      response = await serverRequest.blob();
    } else {
      response = await serverRequest.json();
    }

    const status = await serverRequest.status;
    const statuses = successStatuses || [200];
    const errorsStatuses = { ...errors, ...defaultErrors };

    if (statuses.indexOf(status) !== -1) {
      if (isBlob || success) {
        await success(response, status);
      }
    } else {
      if (failed) {
        await failed(response, status);
      } else {
        for (const key in errorsStatuses) {
          if (errorsStatuses.hasOwnProperty(key)) {
            if (Number(status) === Number(key)) {
              // eslint-disable-next-line no-console
              console.error(path, errorsStatuses[key]);
            }
          }
        }
      }
    }

    if (done) {
      await done(response, status);
    }

    return response;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`ERROR FETCH: ${path}`);
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export default request;
